<script setup lang="ts">
import { useRouter } from "vue-router";

import DoubleDatetimePicker from "@/@core/components/calendar/DoubleDatetimePicker.vue";
import { requestModeler } from "@/config/requestModeler";
import BurstMultiChartsSwitcher from "@core/charts/BurstMultiChartsSwitcher.vue";
import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import { computed, onMounted, ref } from "vue";

import { useConfigStore } from "@/config/configStore";
import { QueryParam } from "@/index";
import ModularNormMultiChartSwitcher from "@core/charts/table/ModularNormMultiChartSwitcher.vue";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";
import { useDateTimeStore } from "@use/useDatetime";
import ModularBurstListTableSwitcher from "@/@core/tables/ModularBurstListTableSwitcher.vue";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const burstData = useBurstDataStore();
const graph = useGraphStore();

const partitionKey = "burst-list-log";

const lensItemId = computed(() => lens.selectedLensItems?.lens_id);

const normGraphId = ref(Math.random() * 1000);
const burstListLogId = computed(() => {
  if (!lens.selectedLensItems?.module) return "";
  const module = Object.values(lens.selectedLensItems?.module)[0];
  // @ts-ignore
  return `${lens.selectedLensItems?.location_id}-${module?.module_id}`;
});

const getBurstListFromCalendar = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_SELECTED_DATE_LOG);
});

const getAllBurstFromListLog = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_ALL_DATE_LOG);
});

const getStartEndDatePicker = computed(() => {
  return datetime.helpers?.startEnd24hourFromDate(datetime.helpers?.today);
});

/**
 * Récupère la liste des journaux de rafales en fonction de la date de début, de la date de fin et de l'identifiant.
 * @param {string} start - La date de début au format string.
 * @param {string} end - La date de fin au format string.
 * @param {any} id - L'identifiant.
 */
const _fetchBurstListLogByDate = async (start: string, end: string, id: any) => {
  if (getBurstListFromCalendar.value && id) {
    // on modifie les paramètres dans la requête venant de l'API avec les valeurs courantes
    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: start,
      },
      {
        key: configs.cosmosQueryParamsEnum.END_DATE,
        value: end,
      },
      {
        key: configs.cosmosQueryParamsEnum.ID,
        value: id,
      },
    ];

    // On recompose la requête avec les vraies valeurs
    const _query_content = getBurstListFromCalendar.value.query_content;
    const query = requestModeler(paramsList, _query_content);
    await burstData.fetchSelectedLensBurstTable({ query, partitionKey, id: burstListLogId.value });
  }
};

const fetchFromCalendarCosmosElements = async ($event: any) => {
  // si on click sur un calendrier vide on vide le tout

  const { startingDate, endDate } = datetime.helpers.startEnd24hourFromDate(
    $event.date,
    lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript
  );

 await _fetchBurstListLogByDate(startingDate, endDate, burstListLogId.value);
};

/**
 * Récupère toutes les données de rafales à partir du journal de liste.
 *
 * @returns {Promise<void>} Une Promise qui se résout lorsque les données de rafales sont récupérées avec succès.
 */
const fetchAllBurstFromListLog = async () => {
  if (getAllBurstFromListLog.value && burstListLogId.value) {
    // Récupère la date de début à partir des metadata dulensGroups sélectionné
    const startingDate = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime;

    // Définit les paramètres de requête pour la requête Cosmos DB
    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.LOCATION_MODULE_ID,
        value: burstListLogId.value,
      },
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: startingDate,
      },
    ] as QueryParam[];

    // Récupère le contenu de la requête à partir de la valeur de getAllBurstFromListLog
    const _query_content = getAllBurstFromListLog.value?.query_content;

    // Modélise la requête en utilisant les paramètres et le contenu de la requête
    const query = requestModeler(paramsList, _query_content);

    // Récupère les données de rafales sélectionnées pour la table de rafales de lens
    await burstData.fetchSelectedLensBurstTable({ query, partitionKey, id: burstListLogId.value });
  }
};

const fetchBurstListLogByStartEnd = async ($event: any) => {
  await _fetchBurstListLogByDate($event.start, $event.end, burstListLogId.value);
};

/**
 * Cette fonction effectue les étapes suivantes :
 * 1. Sélectionne les éléments de la lens en utilisant les paramètres cosmosId et locationId de la route actuelle.
   * 2. Récupère tous les groupes de lens de l'utilisateur.
   * 3. Sélectionne les groupes de lens en utilisant le paramètre locationId de la route actuelle.
   * 4. Récupère toutes les données Burst à partir de la liste de journaux.
   * 5. Génère un nouvel identifiant pour le graphique de normalisation.
   */
const reload = async () => {
  lens.setSelectedLensItems({ cosmosId: router.currentRoute.value.params.cosmosId, locationId: router.currentRoute.value.params.locationId });
  await lensGroup.fetchUserAllLensGroup();
  lensGroup.setSelectedLensGroups(router.currentRoute.value.params.locationId as string);
  fetchAllBurstFromListLog();
  normGraphId.value = Math.random() * 1000;
};

onMounted(async () => {
 await fetchAllBurstFromListLog();
});
</script>

<template>
  <div>
    <LensItemsPageHeader :imageName="'bursts'" @refech-all="reload" />

    <div v-if="lensGroup.selectedLensGroup?.lensGroup_application_type">
      <DoubleDatetimePicker
        @selected-range="fetchBurstListLogByStartEnd"
        :tz="datetime.helpers?.timezone"
        locale="fr-FR"
        :startEndTodayDate="getStartEndDatePicker"
      />
      <ModularNormMultiChartSwitcher
        v-if="Array.isArray(burstData.burstTable) && lens?.selectedLensItems"
        :lensItem="lens?.selectedLensItems"
        :tableData="burstData?.burstTable"
        :applicationType="lens?.selectedLensItems?.data_type"
        :module="lens?.selectedLensItems?.module"
        :key="normGraphId"
      />

      <div class="container px-2 py-4 mx-auto" v-if="burstData.burstTable" >
        <ModularBurstListTableSwitcher
          :tableData="burstData?.burstTable"
          :applicationType="lens?.selectedLensItems?.data_type"
          :module="lens?.selectedLensItems?.module"
          @tableData:update="reload()"
        />

        <BurstMultiChartsSwitcher :chartList="graph.graphCurrent" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
