import * as _ from "lodash";

import { FileConfig, FileConfigCustom } from "@/index";
import { inject, reactive, ref, watch } from "vue";

import { defineStore } from "pinia";
import { saveAs } from "file-saver";
import { useRoute } from "vue-router";
import { useUiStore } from "@use/uiStore";
import { useAPI } from "@/@use/useAPI";

export const useFileStorageStore = defineStore(
  "fileStorage",
  () => {
    const API = useAPI();
    const route = useRoute();

    const reports = ref();
    const csv = ref();
    const customsFiles = ref();
    const allFilesInStore = reactive({
      ids: [],
      entities: {},
    });

    const addEntitiesToList = (id: string, data: any[]) => {
      // @ts-ignore
      allFilesInStore.ids = _.uniq([...allFilesInStore.ids, id]);
      allFilesInStore.entities = _.set(allFilesInStore.entities, `${id}`, data);
    };

    const loadFromEntities = (id: string) => {
      if (allFilesInStore.ids.find((_id) => id === _id)) {
        //@ts-ignore
        return allFilesInStore.entities[id];
      }
    };

    const reMapData = (list: any) => {
      return _.orderBy(
        _.map(list, function (item) {
          return {
            name: item.name,
            ...item.properties,
          };
        }),
        "createdOn",
        "desc"
      );
    };

    /**
     * Récupère les rapports de lens en effectuant une requête HTTP POST vers le serveur.
     *
     * @param {Object} params - Les paramètres de la requête.
     * @param {string} params.id - L'identifiant.
     * @param {string} params.folder - Le dossier.
     * @param {string} params.clientName - Le nom du client.
     * @param {string} params.appType - Le type d'application.
     * @param {string} params.locationPoint - Le point de localisation.
     * @param {string} params.activation_date - La date d'activation.
     * @returns {Promise<Array<any>>} Une Promise qui se résout avec les rapports de lens récupérés.
     */
    const fetchLensReports = async ({
      id,
      folder,
      clientName,
      appType,
      locationPoint,
      activation_date,
    }: {
      id: string;
      folder: string;
      clientName: string;
      appType: string;
      locationPoint: string;
      activation_date: string;
    }) => {
      const _id = `${id}_${appType}_pdf`;
      const _entity = loadFromEntities(_id);
      if (_entity && _entity.length > 0) return (reports.value = _entity);

      const res = await API.postData(
        `${import.meta.env.VITE_BASE_URL}/storage`,
        {
          id,
          folder,
          clientName,
          appType,
          locationPoint,
          activation_date,
        },
      )

      if (res.value.success) {
        reports.value = reMapData(res.value.data);
        reports.value.length > 0 && addEntitiesToList(_id, reports.value);
      }

    };

    /**
     * Récupère le fichier CSV à partir des informations de configuration spécifiées.
     *
     * @param id - L'identifiant du fichier.
     * @param folder - Le dossier du fichier.
     * @param clientName - Le nom du client.
     * @param appType - Le type d'application.
     * @param locationPoint - Le point de localisation.
     * @param activation_date - La date d'activation.
     * @returns Une Promise qui se résout avec le fichier CSV récupéré.
     */
    const fetchLensCSV = async ({ id, folder, clientName, appType, locationPoint, activation_date }: FileConfig) => {
      const _id = `${id}_${appType}_csv`;
      const _entity = loadFromEntities(_id);
      if (_entity && _entity.length > 0) return (csv.value = _entity);
      const res = await API.postData(
        `${import.meta.env.VITE_BASE_URL}/storage`,
        {
          id,
          folder,
          clientName,
          appType,
          locationPoint,
          activation_date,
        },
      )

      if (res.value.success) {
        csv.value = reMapData(res.value.data);
        csv.value.length > 0 && addEntitiesToList(_id, csv.value);
      }
    };

    const fetchFromCustomFolder = async ({ id, folder, clientName, appType, locationPoint, activation_date, customUrl }: FileConfigCustom) => {
      const _id = `${id}_${appType}_custom`;
      const _entity = loadFromEntities(_id);
      if (_entity && _entity.length > 0) return (customsFiles.value = _entity);
      const res = await API.postData(
        `${import.meta.env.VITE_BASE_URL}/storage/customFolder`,
        {
          id,
          folder,
          clientName,
          appType,
          locationPoint,
          activation_date,
          customUrl,
        },
      )
      if (res.value.success) {
        customsFiles.value = reMapData(res.value.data);
        customsFiles.value.length > 0 && addEntitiesToList(_id, customsFiles.value);
      }
    };

    /**
     * Récupère un fichier à partir du stockage.
     *
     * @param name - Le nom du fichier à récupérer.
     * @returns Une Promise qui se résout avec un objet contenant le nom et les données du fichier, ou undefined si le fichier n'existe pas.
     */
    const fetchFileFromStorage = async (name: string): Promise<{ name: string; data: any } | undefined> => {
      const fileName = name.replace(/\//g, "__");
      if (fileName) {
        const res = await API.getData(`storage/${fileName}`);
        const data = res.value.data;
        const name = _.last(_.split(fileName, "__")) as string;
        return { name, data };
      }
    };

    /**
     * Récupère un fichier à partir du stockage et le télécharge.
     *
     * @param file - Le fichier à récupérer.
     * @returns Une Promise qui se résout lorsque le fichier est téléchargé avec succès.
     */
    const fetchFileItem = async (file: any) => {
      // ui.setLoadingState(true);
      // @ts-ignore
      const { name, data } = await fetchFileFromStorage(file.name);
      if (data && data.url) {
        const res = await API.getData(data.url, false, "blob");
        if (res.value.success) {
          const blob = new Blob([res.value.data]);
          saveAs(blob, name);
        }
      }
      // ui.setLoadingState(false);
    };

    // when we change params
    watch(
      () => route?.params,
      (params) => {
        reports.value = undefined;
        csv.value = undefined;
      }
    );

    return {
      reports,
      csv,
      customsFiles,
      fetchFromCustomFolder,
      fetchLensReports,
      fetchLensCSV,
      fetchFileItem,
      allFilesInStore,
    };
  },
  { persist: false }
);
