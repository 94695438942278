import * as _ from "lodash";

import { onMounted, Ref, ref, unref } from "vue";
import { queryAlarmBurstLog, queryAlarmBurstRule, queryAlarmStatsLog, queryAlarmStatsRule } from "./cosmosQueries";

import { defineStore } from "pinia";
import { useAPI } from "@use/useAPI";
import { useRouter } from "vue-router";
import { useUiStore } from "@/@use/uiStore";
import { APIResponse } from "@/@use/types/APIResponse";

export const useAlarmRuleStore = defineStore(
  "alarm",
  () => {
    const router = useRouter();
    const ui = useUiStore();
    const API = useAPI();

    const currentAlarmStatsRule = ref(null);
    const currentAlarmStatsLog = ref(null);

    const currentAlarmBurstRule = ref<any | null>(null);
    const currentAlarmBurstLog = ref(null);

    const allAlarmEntities = ref<any>({
      ids: [],
      entities: {},
    });

    /**
     * Supprime toutes les règles d'alarme d'un module spécifique.
     *
     * @param id - L'identifiant de la règle d'alarme à supprimer.
     */
    const clearFomAllModuleAlarmRules = (id: string) => {
      allAlarmEntities.value.ids = allAlarmEntities.value.ids?.filter((name: string) => name !== id);
      allAlarmEntities.value.entities = _.omit(allAlarmEntities.value.entities, id);
    };

    /**
     * Vérifie si l'identifiant spécifié est présent dans le cache des entités d'alarme.
     *
     * @param id - L'identifiant de l'entité d'alarme à vérifier.
     * @returns L'entité d'alarme correspondante si elle est présente dans le cache, sinon undefined.
     */
    const isInCache = (id: string) => {
      if (!allAlarmEntities.value?.ids?.includes(id)) return allAlarmEntities.value.entities[id];
    };

    /**
     * Ajoute un objet d'alarme au tableau des règles d'alarme.
     *
     * @param {Object} params - Les paramètres de la fonction.
     * @param {any} params.entity - L'entité à ajouter.
     * @param {string} params.id - L'identifiant de l'entité.
     */
    const addAlarmModuleObjectToRules = ({ entity, id }: { entity: any; id: string }) => {
      const withId = { [id]: entity };

      if (!allAlarmEntities.value?.ids?.includes(id)) {
        allAlarmEntities.value?.ids?.push(id);
        allAlarmEntities.value.entities = Object.assign({}, allAlarmEntities.value.entities, withId);
      }
    };

    /**
     * Met à jour ou ajoute une règle à la liste des règles.
     *
     * @param lensGroupRule - Le groupe de règles de lens.
     * @param rule - La règle à mettre à jour ou à ajouter.
     * @param remove - Indique si la règle doit être supprimée si elle existe déjà dans la liste. Par défaut, la règle est supprimée.
     * @returns Le groupe de règles de lens mis à jour.
     */
    const _updateOrAddToRuleList = (lensGroupRule: any, rule: any, remove = true) => {
      // Find an element to recipientList if element found then update it otherwise add it to recipientList
      const index = _.findIndex(lensGroupRule.rules, { id: rule.id });

      if (index !== -1) {
        if (remove) {
          lensGroupRule.rules = lensGroupRule.rules?.filter((item: any) => item.id !== rule.id);
        } else {
          lensGroupRule.rules[index] = rule;
        }
      } else {
        lensGroupRule.rules.push(rule);
      }
      return lensGroupRule;
    };
    /**
     * Récupère les journaux d'alarme statistique pour une localisation donnée.
     *
     * @param locationId - L'identifiant de la localisation pour laquelle récupérer les journaux d'alarme.
     * @returns Une Promise qui se résout avec les journaux d'alarme statistique, ou undefined si aucun journal n'est trouvé.
     */
    /**
     * Récupère les journaux d'alarme statistique pour une localisation donnée.
     *
     * @param locationId - L'identifiant de la localisation pour laquelle récupérer les journaux d'alarme.
     * @returns Une Promise qui se résout avec les journaux d'alarme statistique, ou undefined si aucun journal n'est trouvé.
     */
    const fetchAlarmStatsLog = async (locationId: string) => {
      currentAlarmStatsLog.value = isInCache(`${locationId}-generic-stat-alarm-logs`);
      if (currentAlarmStatsLog.value) return currentAlarmStatsLog.value;

      const cosmosQuery = queryAlarmStatsLog(locationId);

      // ui.setLoadingState(true);

      const response = (await API.fetchFromCosmosWithquery({
        partitionKey: "metadata",
        cosmosQuery: cosmosQuery,
      })) as any;

      if (response?.value && response?.value.length > 0) {
        currentAlarmStatsLog.value = response.value[0];
        // @ts-ignore
        addAlarmModuleObjectToRules({ entity: currentAlarmStatsLog.value, id: currentAlarmStatsLog.value?.id });
        // ui.setLoadingState(false);
        return currentAlarmStatsLog.value;
      } else if (response?.value && response?.value.length === 0) {
        locationId && ui.displayError(`No document found for ${locationId} stat alarm logs`);
      }
      // ui.setLoadingState(false);
    };

    /**
     * Récupère la règle de statistiques d'alarme pour une localisation donnée.
     *
     * @param locationId - L'identifiant de la localisation.
     * @returns La règle de statistiques d'alarme pour la localisation donnée.
     */
    const fetchAlarmStatsRule = async (locationId: string) => {
      currentAlarmStatsRule.value = isInCache(`${locationId}-generic-stat-alarm-rules`);
      if (currentAlarmStatsRule.value) return currentAlarmStatsRule.value;
      // ui.setLoadingState(true);

      const cosmosQuery = queryAlarmStatsRule(locationId);

      const response = (await API.fetchFromCosmosWithquery({
        partitionKey: "metadata",
        cosmosQuery: cosmosQuery,
      })) as any;

      if (response?.value && response?.value.length > 0) {
        currentAlarmStatsRule.value = response.value[0];
        // @ts-ignore
        addAlarmModuleObjectToRules({ entity: currentAlarmStatsRule.value, id: currentAlarmStatsRule.value?.id });
        // ui.setLoadingState(false);
        return currentAlarmStatsRule.value;
      } else if (response && response.length < 1) {
        locationId && ui.displayError(`No document found for ${locationId} stats alarm rules`);
      }

      // ui.setLoadingState(false);
      return currentAlarmStatsRule.value;
    };

    /**
     * Met à jour la règle de statistiques d'alarme pour une localisation spécifique.
     *
     * @param locationId - L'identifiant de la localisation.
     * @param rule - La règle à mettre à jour.
     * @param remove - Indique si la règle doit être supprimée.
     * @returns La valeur de la réponse de la mise à jour de la règle.
     */
    const updateAlarmStatsRule = async (locationId: string, rule: any, remove = false) => {
      clearFomAllModuleAlarmRules(`${locationId}-generic-stat-alarm-rules`);
      const currentAlarmStatsRule = await fetchAlarmStatsRule(locationId);

      if (!_.isEmpty(currentAlarmStatsRule)) {
        const lensGroupRule = _updateOrAddToRuleList(currentAlarmStatsRule, rule, remove) as {};
        const response: Ref<APIResponse> = await API.postData("configs/upsertCosmosData", lensGroupRule);
        return response.value.data;
      }
    };

    const removeAlarmStatsRule = async (locationId: string, rule: any) => {
      return await updateAlarmStatsRule(locationId, rule, true);
    };

    //-------------------- Bursts  ------------------------------

    /**
     * Récupère les journaux d'alarme en rafale pour une localisation donnée.
     *
     * @param locationId - L'identifiant de la localisation.
     * @returns Une Promise qui se résout avec les journaux d'alarme en rafale pour la localisation donnée.
     */
    const fetchAlarmBurstLog = async (locationId: string) => {
      currentAlarmBurstLog.value = isInCache(`${locationId}-generic-burst-alarm-logs`);
      if (currentAlarmBurstLog.value) return currentAlarmBurstLog.value;

      const cosmosQuery = queryAlarmBurstLog(locationId);

      // ui.setLoadingState(true);

      const response = (await API.fetchFromCosmosWithquery({
        partitionKey: "metadata",
        cosmosQuery: cosmosQuery,
      })) as any;

      if (response?.value && response?.value.length > 0) {
        currentAlarmBurstLog.value = response.value[0];
        // @ts-ignore
        addAlarmModuleObjectToRules({ entity: currentAlarmBurstLog.value, id: currentAlarmBurstLog.value?.id });

        return currentAlarmBurstLog.value;
      } else if (response?.value && response?.value.length === 0) {
        locationId && ui.displayError(`No document found for ${locationId} burst alarm logs`);
      }
      // ui.setLoadingState(false);
    };

    /**
     * Récupère la règle d'alarme Burst pour une localisation donnée.
     *
     * @param locationId - L'identifiant de la localisation.
     * @returns La règle d'alarme Burst pour la localisation donnée.
     */
    const fetchAlarmBurstRule = async (locationId: string) => {
      currentAlarmBurstRule.value = isInCache(`${locationId}-generic-burst-alarm-rules`);
      if (currentAlarmBurstRule.value) return currentAlarmBurstRule.value;

      const cosmosQuery = queryAlarmBurstRule(locationId);

      // ui.setLoadingState(true);

      const response = await API.fetchFromCosmosWithquery({
        partitionKey: "metadata",
        cosmosQuery: cosmosQuery,
      });

      if (response?.value && response?.value.length > 0) {
        currentAlarmBurstRule.value = response.value[0];

        addAlarmModuleObjectToRules({ entity: currentAlarmBurstRule.value, id: currentAlarmBurstRule.value?.id });
        // ui.setLoadingState(false);
        return currentAlarmBurstRule.value;
      } else if (response && response.length < 1) {
        locationId && ui.displayError(`No document found for ${locationId} burst alarm rules`);
      }
      // ui.setLoadingState(false);

      return currentAlarmBurstRule.value;
    };

    /**
     * Met à jour la règle de déclenchement d'alarme pour une localisation spécifique.
     *
     * @param locationId - L'identifiant de la localisation.
     * @param rule - La règle de déclenchement d'alarme à mettre à jour.
     * @param remove - Indique si la règle doit être supprimée.
     * @returns La valeur de la réponse de la mise à jour de la règle de déclenchement d'alarme.
     */
    const updateAlarmBurstRule = async (locationId: string, rule: any, remove = false) => {
      clearFomAllModuleAlarmRules(`${locationId}-generic-burst-alarm-rules`);
      const currentAlarmBurstRule = await fetchAlarmBurstRule(locationId);
      if (!_.isEmpty(currentAlarmBurstRule)) {
        const lensGroupRule = _updateOrAddToRuleList(currentAlarmBurstRule, rule, remove) as {};
        const response: Ref<APIResponse> = await API.postData("configs/upsertCosmosData", lensGroupRule);
        return response.value.data;
      }
    };

    const removeAlarmBurstRule = async (locationId: string, rule: any) => {
      return await updateAlarmBurstRule(locationId, rule, true);
    };

    const clearCurrentAlarmRules = () => {
      currentAlarmStatsRule.value = null;
      currentAlarmStatsLog.value = null;
      currentAlarmBurstRule.value = null;
      currentAlarmBurstLog.value = null;
    };

    router?.beforeEach(async (to, from, next) => {
      clearCurrentAlarmRules();
      if (!to.name?.toString()?.includes("csv")) {
        if (to.name?.toString()?.includes("statistics")) {
          await fetchAlarmStatsLog(to.params.locationId as string);
          await fetchAlarmStatsRule(to.params.locationId as string);
        }
        if (to.name?.toString()?.includes("bursts")) {
          await fetchAlarmBurstRule(to.params.locationId as string);
        }
      }
      next();
    });

    onMounted(() => {
      clearCurrentAlarmRules();
    });

    return {
      allAlarmEntities,
      fetchAlarmStatsRule,
      removeAlarmStatsRule,
      updateAlarmStatsRule,
      currentAlarmStatsRule,
      currentAlarmStatsLog,
      fetchAlarmStatsLog,

      // Burst --------------
      fetchAlarmBurstRule,
      removeAlarmBurstRule,
      updateAlarmBurstRule,
      currentAlarmBurstRule,
      currentAlarmBurstLog,
      fetchAlarmBurstLog,
    };
  },
  {
    persist: true,
  }
);
