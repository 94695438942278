import { Ref, inject, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import CryptoJS from "crypto-js";
import { createFetch } from "@vueuse/core";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";
import { defineStore } from "pinia";
import moment from "moment";
import { useUiStore } from "@use/uiStore";
import { AxiosInstance, AxiosResponse, ResponseType } from "axios";
import { APIResponse } from "./types/APIResponse";

export const useAPI = defineStore("api", () => {
  const ui = useUiStore();
  const router = useRouter();
  const route = useRoute();

  const fetchCosmos = reactive({
    queryId: [''],
    debounce: new Date(),
  });

  const commonAPI = inject('axios') as AxiosInstance;

  commonAPI.interceptors.response.use((response: AxiosResponse) => {
    if(response.status === 406 && route.name !== "home") {
      ui.displayError(`Please log in with correct permissions`);
      router.push({ name: "home" });
    }
    return response;
  })

  // const toaster = createToaster({
  //   position: "top-right",
  // });

  /**
   * Les paramètres des en-têtes pour les requêtes API.
   *
   * @remarks
   * Cette variable réactive contient les paramètres des en-têtes utilisés pour les requêtes API. Les en-têtes incluent les informations telles que le type de contenu, l'acceptation des réponses JSON et l'autorisation avec un jeton d'accès.
   *
   * @example
   * ```
   * const headersSettings = reactive({
   *   headers: {
   *     Accept: "application/json",
   *     "Content-Type": "application/json",
   *     Authorization: `Bearer `,
   *   },
   * });
   * ```
   */
  const headersSettings = reactive({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer `,
    },
  });

  /**
   * Vérifie si le jeton d'accès est invalide.
   *
   * @param serverData - Les données renvoyées par le serveur.
   * @returns Retourne `false` si le jeton est invalide, sinon `undefined`.
   */
  const isTokenInvalid = (serverData: any) => {
    if (serverData && serverData.statusCode === 406 && route.name !== "home") {
      // ui.setLoadingState(false);
      ui.displayError(`Please log in with correct permissions`);
      router.push({ name: "home" });
      return false;
    }
  };

  /**
   * Récupère le jeton d'accès à partir du stockage local.
   *
   * @returns Le jeton d'accès.
   */
  const getToken = async () => {
    try {
      const storage = (await localStorage.getItem("auth")) || false;
      if (storage) {
        const res = JSON.parse(storage);
        headersSettings.headers.Authorization = `Bearer ${res?.token?.accessToken}`;
        return res?.token?.accessToken;
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Crée une instance de l'API avec les options spécifiées.
   * Cette API utilise une URL de base définie dans les variables d'environnement.
   * Avant chaque requête, cette fonction ajoute un en-tête d'autorisation avec un jeton d'accès valide, s'il existe.
   * L'en-tête Accept est également défini sur "application/json".
   * Les options de fetch utilisées incluent le mode "cors".
   *
   * @returns Une instance de l'API configurée avec les options spécifiées.
   */
  const oldAPI = createFetch({
    baseUrl: `${import.meta.env.VITE_BASE_URL}`,
    options: {
      async beforeFetch({ options }: { options: any }) {
        const token = await getToken();

        options.headers.Authorization = token ? `Bearer ${token}` : `Bearer null`;
        options.headers.Accept = "application/json";
        return { options };
      },
    },
    fetchOptions: {
      mode: "cors",
    },
  });

  const getData = async (url: string, useAuth: boolean = true, responseType: ResponseType = "json"): Promise<Ref<APIResponse>> => {
    const result = ref<APIResponse>({
      data: null,
      status: -1,
      success: false,
    });
    try {
      const { data, status } = await commonAPI.get(url, {
        headers: {
          useAuth
        },
        responseType,
      });
      result.value = {
        data,
        status,
        success: status === 200,
      }
    } catch (error: any) {
      result.value.error = error.message;
    } finally {
      // console.log("result", result.value);
      return result;
    }
  }

  const postData = async (url: string, payload: {}, useAuth: boolean = true): Promise<Ref<APIResponse>> => {
    const result = ref<APIResponse>({
      data: null,
      status: -1,
      success: false,
    });
    try {
      const { data, status } = await commonAPI.post(url, payload, {
        headers: {
          useAuth
        }
      });
      result.value = {
        data,
        status,
        success: status === 200,
      }
    } catch (error: any) {
      result.value.error = error.message;
    } finally {
      // console.log("result", result.value);
      return result;
    }
  };

  const putData = async (url: string, payload: {}): Promise<Ref<APIResponse>> => {
    const result = ref<APIResponse>({
      data: null,
      status: -1,
      success: false,
    });
    try {
      const { data, status } = await commonAPI.put(url, payload, {
        headers: {
          useAuth: true
        },
      });
      result.value = {
        data,
        status,
        success: status === 200,
      }
    } catch (error: any) {
      result.value.error = error.message;
    } finally {
      // console.log("result", result.value);
      return result;
    }
  };

  const deleteData = async (url: string, payload: string): Promise<Ref<APIResponse>> => {
    const result = ref<APIResponse>({
      data: null,
      status: -1,
      success: false,
    });
    try {
      const { data, status } = await commonAPI.delete(`${url}/${payload}`, {
        headers: {
          useAuth: true
        },
      });
      result.value = {
        data,
        status,
        success: status === 200,
      }
    } catch (error: any) {
      result.value.error = error.message;
    } finally {
      // console.log("result", result.value);
      return result;
    }
  };

  /**
   * Récupère les données à partir de l'URL spécifiée.
   *
   * @param url - L'URL à partir de laquelle récupérer les données.
   * @returns Une promesse qui se résout avec les données récupérées.
   */
  const getDataOld = async (url: string): Promise<any> => {
    try {
      let result: any = ref(false);
      // ui.setLoadingState(true);
      const { data, error, abort, canAbort } = await oldAPI(url).get().json();
      if (error.value) {
        // ui.displayError(`Error, Please try again!`);
        result.value = isTokenInvalid(data.value);
      }

      if (canAbort.value) {
        abort();
      }

      if (data.value) {
        result.value = data.value;
      }

      // ui.setLoadingState(false);
      return result;
    } catch (error: any) {
      ui.displayError(`${error}`);
      // ui.setLoadingState(false);
    }
  };

  /**
   * Envoie une requête POST à l'URL spécifiée avec les données fournies.
   *
   * @param url L'URL de destination de la requête POST.
   * @param payload Les données à envoyer avec la requête POST.
   * @returns Une promesse qui se résout avec le résultat de la requête POST.
   */
  const postDataOld = async (url: string, payload: {}): Promise<any> => {
    try {
      let result: any = ref(false);
      // ui.setLoadingState(true);
      const { data, error, abort, canAbort } = await oldAPI(url).post(payload).json();

      if (error.value) {
        // ui.displayError(`Error, Please try again!`);
        result.value = isTokenInvalid(data.value);
      }

      if (canAbort.value) {
        abort();
      }

      if (data.value) {
        result.value = data.value;
      }

      // ui.setLoadingState(false);
      return result;
    } catch (error: any) {
      ui.displayError(`${error}`);
      // ui.setLoadingState(false);
    }
  };

  /**
   * Effectue une requête PUT vers l'URL spécifiée avec les données fournies.
   * @param url L'URL de la requête PUT.
   * @param payload Les données à envoyer dans la requête.
   * @returns Une promesse qui se résout avec le résultat de la requête.
   */
  const putDataOld = async (url: string, payload: {}): Promise<any> => {
    try {
      let result: any = ref(false);
      // ui.setLoadingState(true);
      const { data, error, abort, canAbort } = await oldAPI(url).put(payload).json();

      if (error.value) {
        // ui.displayError(`Error, Please try again!`);
        result.value = isTokenInvalid(data.value);
      }

      if (canAbort.value) {
        abort();
      }

      if (data.value) {
        result.value = data.value;
      }
      // ui.setLoadingState(false);

      return result;
    } catch (error: any) {
      ui.displayError(`${error}`);
      // ui.setLoadingState(false);
    }
  };

  /**
   * Supprime les données en effectuant une requête DELETE à l'URL spécifiée avec le payload donné.
   * @param url L'URL de la requête DELETE.
   * @param payload Le payload de la requête DELETE.
   * @returns Une promesse qui se résout avec le résultat de la requête DELETE.
   */
  const deleteDataOld = async (url: string, payload: string | number): Promise<any> => {
    try {
      let result: any = ref(false);
      // ui.setLoadingState(true);
      const { data, error, abort, canAbort } = await oldAPI(`${url}/${payload}`).delete().json();

      if (error.value) {
        // ui.displayError(`Error, Please try again!`);
        result.value = isTokenInvalid(data.value);
      }

      if (canAbort.value) {
        abort();
      }

      if (data.value) {
        result.value = data.value;
      }

      // ui.setLoadingState(false);
      return result;
    } catch (error: any) {
      ui.displayError(`${error}`);
      // ui.setLoadingState(false);
    }
  };
  /**
   * Effectue une requête vers Cosmos avec la charge utile spécifiée.
   * @param payload La charge utile de la requête.
   * @returns Une promesse qui se résout avec le résultat de la requête.
   */
  const fetchFromCosmosWithquery = async (payload: any): Promise<any> => {
    try {
      let result: any = ref(false);
      // ui.setLoadingState(true);

        const isNotDebounced = moment(new Date()).diff(moment(fetchCosmos.debounce), "milliseconds") < 1600
        const isSameQuery = fetchCosmos.queryId.includes(CryptoJS.MD5(payload.cosmosQuery).toString())

      if (!(isNotDebounced && isSameQuery )) {
        fetchCosmos.queryId.push(CryptoJS.MD5(payload.cosmosQuery).toString())
        fetchCosmos.debounce = new Date();

        const { data, error, abort, canAbort } = await oldAPI("configs/fetchFromCosmosWithquery").post(payload).json();

        if (!isNotDebounced) {
          fetchCosmos.queryId = ['']
        }

        if (error.value) {
          // ui.displayError(`Error, Please try again!`);
          result.value = isTokenInvalid(data.value);
        }

        if (canAbort.value) {
          abort();
        }

        if (data.value) {
          result.value = data.value;
        }
        // ui.setLoadingState(false);

        return result;
      } else {

        return // ui.setLoadingState(false);
      }
    } catch (error: any) {
      ui.displayError(`${error}`);
      // ui.setLoadingState(false);
    }
  };

  return {
    headersSettings,
    getToken,
    getData,
    getDataOld,
    deleteData,
    putData,
    postData,
    fetchFromCosmosWithquery,
  };
});
