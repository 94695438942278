import axios from 'axios';

const commonAPI = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

const getToken = async () => {
  try {
    const storage = await localStorage.getItem("auth");
    if (storage) {
      const res = JSON.parse(storage);
      return res?.token?.accessToken;
    }
  } catch (error) {
    console.error('Error getting token:', error);
  }
  return null;
};

commonAPI.interceptors.request.use(async (config) => {
  const token = await getToken();
  if (config.url !== '/auth/login' && config.headers.useAuth) {
    config.headers.Authorization = token ? `Bearer ${token}` : `Bearer null`;
    config.headers.Accept = "application/json";
  }
  delete config.headers.useAuth;
  return config;
});

export { commonAPI };

