import * as _ from 'lodash'

import { ApplicationType, ClientApplicationOption, MeasurementPoint } from "@/index";
import { computed, onMounted, Ref, ref } from "vue";

import { defineStore } from "pinia";
import { updateOrAddMeasurementPoint } from "@/@use/libs/updateOrAddMeasurementPoint";
import { useAPI } from "@use/useAPI";
import { useAuthStore } from "@/@modules/auth/authStore";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useUiStore } from "@/@use/uiStore";
import { APIResponse } from '@/@use/types/APIResponse';

export const metadataOptionStore = defineStore("metadataOption", () => {
  const API = useAPI();
  const ui = useUiStore();
  const metadata = useMetadataStore();
  const auth = useAuthStore();

  const clientApplication = ref<any>();
  const applicationTypes: ApplicationType[] = ["generic"];
  const moduleTypes: string[] = ["NONE", "din4150-3"];
  const lastApplicationId = ref(0);

  const orgName = computed(() => auth.user.user_org && auth.user.user_org.org_name);
  /**
   * Récupère l'ID de la dernière application en effectuant une requête à Cosmos DB.
   *
   * @returns Une promesse qui se résout avec l'ID de la dernière application.
   */
  const fetchLastApplicationId = async () => {
    const cosmosQuery = `SELECT * FROM c WHERE c.id = "last-application-id"`;
    const lastAppId = (await API.fetchFromCosmosWithquery({
      partitionKey: "metadata",
      cosmosQuery: cosmosQuery,
    })) as any;
    if (lastAppId?.value && lastAppId.value.length > 0) {
      lastApplicationId.value = lastAppId.value[0].last_id
    }
  };

  /**
   * Met à jour l'identifiant de la dernière application.
   * Cette fonction récupère la valeur actuelle de l'identifiant de la dernière application,
   * incrémente cette valeur de 1, puis met à jour l'identifiant dans la base de données Cosmos.
   */
  const updateLastApplicationId = async () => {
    const appId = {
      id: "last-application-id",
      partitionKey: "metadata",
      last_id: lastApplicationId.value + 1
    };
    await API.postData("configs/upsertCosmosData", appId);
  };

  /**
   * Récupère les options de configuration du client.
   *
   * @param clientName - Le nom du client.
   * @returns Les options de configuration du client.
   */
  const fetchClientApplicationOption = async (clientName: string) => {
    // ui.setLoadingState(true);

    const response = await API.getData(`configs/client_configuration/${_.trim(_.toLower(clientName))}`);
    await fetchLastApplicationId();
    if (response.value) {
      clientApplication.value = response.value.data;
    }

    // ui.setLoadingState(false);
    return response.value.data;
  };

  /**
   * Met à jour l'option d'application client.
   *
   * @param clientName - Le nom du client.
   * @param measurementPoint - Le point de mesure.
   * @param type - Le type d'application.
   * @param remove - Indique si l'option doit être supprimée.
   * @returns La valeur de la réponse.
   */
  const updateClientApplicationOption = async (clientName: string, measurementPoint: MeasurementPoint, type: ApplicationType, remove = false) => {
    clientApplication.value = await fetchClientApplicationOption(clientName);
    const _clientApplication =
      clientApplication.value && (updateOrAddMeasurementPoint(clientApplication.value.clientData, measurementPoint, type, remove) as {});
      await updateLastApplicationId();

    const response: Ref<APIResponse> = await API.postData("configs/upsertCosmosData", _clientApplication);
    return response.value.data;
  };

  /**
   * Supprime un point de mesure de l'option d'application d'un client.
   *
   * @param clientName - Le nom du client.
   * @param measurementPoint - Le point de mesure à supprimer.
   * @param type - Le type d'application.
   * @param remove - Indique si le point de mesure doit être supprimé (par défaut: true).
   * @returns Une promesse qui se résout lorsque la mise à jour de l'option d'application du client est terminée.
   */
  const removeMeasurementPointFromApplicationOption = async (clientName: string, measurementPoint: MeasurementPoint, type: ApplicationType, remove = true) => {
    await updateClientApplicationOption(clientName, measurementPoint, type, remove);
  };

  onMounted(async () => {
    await fetchLastApplicationId()
    await metadata.fetchMasterConfigurations();
    if (orgName.value) await fetchClientApplicationOption(orgName.value);
  });

  return {
    lastApplicationId,
    clientApplication,
    applicationTypes: computed(() => applicationTypes),
    moduleTypes: computed(() => moduleTypes),
    fetchClientApplicationOption,
    fetchLastApplicationId,
    updateLastApplicationId,
    updateClientApplicationOption,
    removeMeasurementPointFromApplicationOption,
  };
} , { persist: true});
