<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { requestModeler } from "@/config/requestModeler";
import TableData from "@core/components/tables/elements/TableData.vue";
import useTable from "@core/components/tables/elements/useTable";

import { findDisplayNameByChannelName } from "@/@core/charts/dataFormatters/shared/findDisplayNameByChannelName";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useConfigStore } from "@/config/configStore";
import { PhysicalQuantity } from "@/index";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useDateTimeStore } from "@use/useDatetime";
import { burstGenericListTableColumns } from "./burstGenericListTableColumns";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";

import CommentDialog from "@core/comments/CommentDialog.vue";
import Modal from "@core/shared/ui/Modal.vue";

import moment from "moment-timezone";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Chip from 'primevue/chip';

const emit = defineEmits(["tableData:select-bursts-id", "tableData:reload"]);
const props = defineProps<{ tableData: any[] }>();

const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const lens = useLensStore();
const graph = useGraphStore();
const lensGroup = useLensGroupStore();
const metadata = useMetadataStore();

const toDate = (cell: any, row: any) => datetime.helpers.to24HourFormat(cell);

const options = {
  mainOrder: "datetime",
  mainOrderType: "date",
};
const table = useTable();

const showCommentModal = ref(false);
const commentContent = ref();

const physicalQuantity = computed(() => metadata.physicalQuantities);
const getPhysicalByType = (type = "velocity") => {
  return physicalQuantity.value?.find((ph: PhysicalQuantity) => ph.type === type);
};

const toExternalPQUnit = (value: any, unit: any) => {
  if (value && unit) {
    const physicalQ = getPhysicalByType(unit);
    const fQ =
      typeof physicalQ?.f_to_external_unit.evaluate === "function"
        ? physicalQ.f_to_external_unit.evaluate({ x: value }).toFixed(physicalQ.user_decimal_digits)
        : value;
    return `${fQ} ${physicalQ?.external_unit}`;
  }
};

const genericLensVariables = computed(() => metadata.selectedMetadata.lens_variables);
const genericBurstsChartOption = (burstId: number) => metadata.selectedMetadataGenericBurstChartCustom?.options[`burst_${burstId}`].lens_chart;

const displayLensVariableName = findDisplayNameByChannelName(lensGroup.selectedLensGroup?.lensGroup_data.metadata.lens_variables!);
const fetchDataFromCosmosById = (id: string, burstId: number) => {
  const lensInfos = Object.assign({}, lens.selectedLensItems, { lens_variables: genericLensVariables.value });
  const lensChartOptions = genericBurstsChartOption(burstId);

  const formatter = lensInfos?.data_type;

  const partitionKey = lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id;
  // const partitionKey = "E11";
  if (partitionKey) {
    // const burstDetails = lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const burstDetails = lens.selectedLensItems?.lens_queries?.find((el: any) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const paramsList = [{ key: configs.cosmosQueryParamsEnum.ID, value: id }];
    // @ts-ignore
    const query = requestModeler(paramsList, burstDetails?.query_content);

    // @ts-ignore
    graph.setSelectedLensBurstGenericGraphCurrent({
      query,
      lensInfos,
      partitionKey,
      lensChartOptions,
      formatter,
    });
  }
};
const sortItem = ($event: any) => {
  console.log($event);
};

const displayCommentDialog = (elem: any) => {
  commentContent.value = elem;
  showCommentModal.value = !showCommentModal.value;
};

const hideCommentDialog = () => {
  commentContent.value = null;
  showCommentModal.value = false;
};

const saveNote = async ($event: any) => {
  await useBurstDataStore().saveBurstLogNote($event);
  emit("tableData:reload");
  hideCommentDialog();
};

const burstsColumns = burstGenericListTableColumns({
  fetchDataFromCosmosById,
  toExternalPQUnit,
  toDate,
  displayLensVariableName,
  displayCommentDialog,
});

onMounted(() => {
  if (props.tableData) {
    table.setInitialSwitchData(props.tableData, "visible");
  }
});
</script>
<template>
  <div>
    <!-- <TableData :columns="burstsColumns" :rows="tableData" :options="options" @sort-item="sortItem($event)" /> -->
    <DataTable :value="tableData" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="datetime" paginator :rows="20" :rowsPerPageOptions="[20, 50]">
      <Column field="datetime" header="Datetime" :sortable="true">
        <template #body="slotProps">
          <div>
            {{ moment(slotProps.data.datetime).format("D MMM, yyyy HH:mm:ss") }}
          </div>
        </template> 
      </Column>
      <Column field="burst_id" header="Burst ID"></Column>
      <Column header="Duration">
        <template #body="slotProps">
          <div>{{ slotProps.data.burst_duration.value }} s</div>
        </template> 
      </Column>
      <Column header="Peak infos">
        <template #body="slotProps">
          <div>
            <span v-for="(value, key, index) in slotProps.data.burst_stat">
              <strong>{{ key }}: </strong> <Chip class="mx-1 px-4 py-1 bg-blue-200 rounded-sm">peak: {{  toExternalPQUnit(value?.peak, value?.physical_quantities) }}</Chip>
            </span>
          </div>
        </template> 
      </Column>
      <Column header="">
        <template #body="slotProps">
          <div style="width: 160px;display: flex;">
            <Button class="m-auto inline-block px-4 py-1" label="show" severity="info" @click="fetchDataFromCosmosById(slotProps.data.name, slotProps.data.burst_id)" raised/> <!--   -->
          </div>
        </template> 
      </Column>
    </DataTable>
    <Modal :show="showCommentModal" @reset="hideCommentDialog">
      <CommentDialog :innerData="commentContent" @save="saveNote" />
    </Modal>
  </div>
</template>
