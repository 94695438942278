<script setup lang="ts">
import { onBeforeMount, Ref, ref } from "vue";
import * as _ from "lodash";
import { phone as PhoneValidator } from "phone";
//@ts-ignore
import { VueTelInput } from 'vue-tel-input';
//@ts-ignore
import * as EmailValidator from "email-validator";

import InputText from 'primevue/inputtext';

// @ts-ignore
// import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import { handleSubmit, setValues, errors, email, name, phone, setFieldError } from "@core/forms/OrgRecipientCreateUpdateForm"

const props = defineProps<{
  recipientObjectData: any;
  isNewRecipient: Boolean;
}>();
const emit = defineEmits(["submit-form"]);

const defaultMask = "+99 9 99 99 99 99"

const mask = ref(defaultMask)

const onSubmit = handleSubmit((values) => {
  console.log('Submitted with', values);
  const { id, ...rest } = props.recipientObjectData;
  if (_.isEmpty(values.email.trim())) {
    values.email = "-/-"
  }
  if (_.isEmpty(values.phone.trim())) {
    values.phone = "-/-"
  }
  if (values.email === "-/-" && values.phone === "-/-") {
    setFieldError("email", "Email or Phone is required")
    setFieldError("phone", "Email or Phone is required")
  } else {
    if (values.phone !== "-/-") {
      if (PhoneValidator(values.phone).isValid) {
        values.phone = PhoneValidator(values.phone).phoneNumber;
      } else {
        setFieldError("phone", "Invalid phone number")
      }
    }
    if(values.email !== "-/-") {
      if (EmailValidator.validate(values.email)) {
        values.email = values.email.toLowerCase().trim();
      } else {
        setFieldError("email", "Invalid email address")
      }
    }
  }
  if (_.isEmpty(errors.value)) {
    emit("submit-form", {id, ...values});
  }
});

const handlePhoneInput = ($event: any) => {
  if (phone.value === "") {
    mask.value = defaultMask
  }
}


onBeforeMount(() => {
  if (!props.isNewRecipient) {
    const data = {...props.recipientObjectData}
    mask.value = data.phone === "-/-" ? "-/-" : defaultMask
    setValues(data)
  } else {
    mask.value = "-/-"
    setValues({
      email: "-/-",
      name: "-/-",
      phone: "-/-",
    }, false)
  }
})
</script>
<style>
.size {
  width: 500px;
}
</style>

<template>
  <div v-if="recipientObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ props.isNewRecipient ? "Add new recipient" : "Update recipient" }}
      </h1>
      <form class="flex flex-col space-y-3 size" @submit.prevent="onSubmit">
        <label for="name">Name</label>
        <InputText id="name" type="text" v-model="name"/>
        <small id="name-help" class="p-error text-red-500">
          {{ errors.name }}
        </small>
        <label for="email">Email</label>
        <InputText id="email" type="text" v-model="email"/>
        <small id="email-help" class="p-error text-red-500">
          {{ errors.email }}
        </small>
        <label for="phone">Phone</label>
        <!--<InputMask id="phone" :mask="mask" v-model="phone" class="" v-on:keydown="handlePhoneInput"/>-->
        <!-- <MazPhoneNumberInput
          v-model="phone"
          v-model:country-code="countryCode"
          show-code-on-list
          default-phone-number="-/-"
          :no-validation-error="true"
          :error="!!errors.phone"
        /> -->
        <vue-tel-input v-model="phone"></vue-tel-input>
        <small id="phone-help" class="p-error text-red-500">
          {{ errors.phone }}
        </small>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          >
            {{ props.isNewRecipient ? "Create" : "Update" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
