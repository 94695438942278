<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script lang="js">
import { useUiStore } from "@use/uiStore";
import { mapStores } from "pinia";
import AdminLayout from "./@core/layouts/AdminLayout.vue";
import HomeLayout from "./@core/layouts/HomeLayout.vue";

const defaultLayout = "default-layout";

import { useAPI } from "./@use/useAPI";

export default {
  components: {
    HomeLayout,
    AdminLayout,
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ? HomeLayout : AdminLayout;
    },
    ...mapStores(useUiStore),
    ...mapStores(useAPI),
  },
  async beforeMount() {
    await this.uiStore.setColdStartState(true);
    const res = await this.apiStore.getData(`${import.meta.env.VITE_BASE_URL}/auth/start`, false);
    if (res.value.success) {
      this.uiStore.setColdStartState(false);
    }
  },
};
</script>
