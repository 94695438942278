<script setup lang="ts">
import { ArrowRightIcon } from "@heroicons/vue/outline";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed, inject, toRaw, watchEffect } from "vue";

import UserUpdateForm from "@core/forms/UserUpdateForm.vue";
import MainHeading from "@core/shared/ui/headings/MainHeading.vue";
import Modal from "@core/shared/ui/Modal.vue";

import { useLensGroupStore } from "@/@modules/admin/store/lensGroupStore";
import { useUiStore } from "@/@use/uiStore";
import { User, UserUpdate } from "@/index";
import { useConfigurationStore } from "@modules/configuration/store/configurationStore";
import { useAPI } from "@use/useAPI";
import UserDelete from "./UserDelete.vue";
import { useConfigStore } from "@/config/configStore";

import { FilterMatchMode } from '@primevue/core/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Select from 'primevue/select';
import Tag from 'primevue/tag';


const $zo: any = inject("$zo");
const ui = useUiStore();
const API = useAPI();

const lensGroup = useLensGroupStore();
const configurationStore = useConfigurationStore();
const config = useConfigStore();

const { users, selectedUser } = storeToRefs(configurationStore);
const { enumerationData } = storeToRefs(config);

const showUpdateUserModal = ref<boolean>(false);
const showDeleteUserModal = ref<boolean>(false);


// get org_display_name organizations
const organizations = computed(() => {
   return configurationStore.organizations.map((el: any) => {
    return (el as { org_display_name: string }).org_display_name;
  });
});



const organizationReference = ref(organizations.value)

// watch for changes in organizations (refresh page)
watchEffect(() => {
  organizationReference.value = organizations.value;
});


const filters = ref({
    'user_org.org_display_name': { value: null, matchMode: FilterMatchMode.EQUALS },
});



// const lensGroups = ref([])
const selectedLensGroups = computed(() => {
  const isSuperAdmin = $zo.getRoles() === enumerationData.value?.roleTypesEnum.SUPER_ADMIN
  if(isSuperAdmin) {
    return configurationStore.lensGroups
  } else {
    return lensGroup.lensGroups

  }
});

const usersData = computed(() => {
  const results = users.value.map((el) => {
    const lensGroups = toRaw(el?.user_lensGroups)
    const result = {
      ...el,
      user_org: toRaw(el?.user_org) || {},
      user_role: toRaw(el?.user_role) || {},
      user_lensGroups: lensGroups
    }
    return result;
  });
  return results;
});

/**
 * Modifie l'utilisateur sélectionné.
 *
 * @param {User} user - L'utilisateur à modifier.
 */
function editSelectedUser(user: User) {
  // ui.setLoadingState(true);
  configurationStore.setSelectedUser(user.id);
  if (selectedUser.value) {
    showUpdateUserModal.value = !showUpdateUserModal.value;
  }
  // ui.setLoadingState(false);
}

/**
 * Supprime l'utilisateur sélectionné.
 *
 * @param {Object} user - L'utilisateur à supprimer.
 */
function deleteSelectedUser(user: any) {
  // console.log(user.id)
  configurationStore.setSelectedUser(user.id);
  showDeleteUserModal.value = !showDeleteUserModal.value;
}
/**
 * Soumet le formulaire de mise à jour de l'utilisateur.
 *
 * @param {UserUpdate} formData - Les données du formulaire de mise à jour de l'utilisateur.
 * @returns {Promise<void>} - Une promesse qui se résout lorsque la mise à jour de l'utilisateur est terminée.
 */
async function submitUpdateUserModal(formData: UserUpdate) {
  // ui.setLoadingState(true);
  const response = await API.putData("users/update", formData);

  if (response.value.success) {
    await lensGroup.fetchUserAllLensGroup();
    await configurationStore.fetchAllUsers();
    await configurationStore.fetchAllLensGroups();
  }
  // ui.setLoadingState(false);
  showUpdateUserModal.value = !showUpdateUserModal.value;
}

/**
 * Soumet la suppression d'un utilisateur.
 *
 * Cette fonction effectue les étapes suivantes:
 * 1. Définit l'état de chargement de l'interface utilisateur sur true.
 * 2. Vérifie si un utilisateur est sélectionné.
 * 3. Ferme la fenêtre modale de suppression de l'utilisateur.
 * 4. Appelle l'API pour supprimer les données de l'utilisateur sélectionné.
 * 5. Si la suppression réussit, met à jour la liste des utilisateurs et attend 200ms.
 * 6. Inverse l'état de la fenêtre modale de suppression de l'utilisateur.
 * 7. Définit l'état de chargement de l'interface utilisateur sur false.
 */
async function submitDeleteUser() {
  ui.setLoadingState(true);
  if (selectedUser.value) {
    showDeleteUserModal.value = false;
    const response = await API.deleteData(`users`, selectedUser.value.id.toString());
    if (response.value.success) {
       await configurationStore.fetchAllUsers();
    }
  }
  ui.setLoadingState(false);
}

onMounted(async () => {
  await configurationStore.fetchAllUsers();
  await lensGroup.fetchUserAllLensGroup();
  await configurationStore.fetchAllLensGroups();
});
</script>

<template>
  <div class="container px-2 py-4 mx-auto">
    <MainHeading title="User list" subtitle="">
      <template v-slot:button>
        <router-link
          :to="{ name: 'auth-register' }"
          class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
        >
          <span class="mr-4 flex items-start flex-col leading-none">{{ $t("configuration.createUser") }}</span>
          <ArrowRightIcon class="h-6 w-6" />
        </router-link>
      </template>
    </MainHeading>



    <DataTable v-model:filters="filters" :value="usersData" tableStyle="min-width: 50rem" filterDisplay="row" :globalFilterFields="['user_org.org_display_name']">
      <Column field="user_org.org_display_name" header="Organization" :showFilterMenu="false">
       
       <template #filter="{ filterModel, filterCallback }">
           <Select v-model="filterModel.value" @change="filterCallback()" :options="organizationReference" placeholder="Select One"  :showClear="true" >
               <template #option="slotProps">
                  {{ slotProps.option }}
               </template>
           </Select>
       </template>
</Column>
      <Column field="username" header="Nickname" :sortable="true"></Column>
      <Column field="user_email" header="User account" :sortable="true"></Column>
      <Column field="user_fullname" header="Full name" :sortable="true"></Column>
   
      <Column field="user_role.role_name" header="Role" :sortable="true">
      </Column>
      <Column header="Assigned device(s)">
        <template #body="slotProps">
          <div v-for="item in slotProps.data.user_lensGroups">{{ item?.lensGroup_name }}</div>
        </template>
      </Column>
      <Column header="">
        <template #body="slotProps">
          <div style="width: 160px;display: flex;">
            <Button class="m-auto inline-block px-4 py-1" label="Edit" @click="editSelectedUser(slotProps.data)" severity="info" raised/>
            <Button class="m-auto inline-block px-4 py-1" label="Delete" @click="deleteSelectedUser(slotProps.data)" severity="danger" raised/>
          </div>
        </template> 
      </Column>
    </DataTable>

    <Modal :show="showDeleteUserModal" @reset="showDeleteUserModal = false">   
      <UserDelete :userToDelete="selectedUser" @confirm="submitDeleteUser" @reset="showDeleteUserModal = false" />
    </Modal>
    <Modal :show="showUpdateUserModal" @reset="showUpdateUserModal = false">
      <UserUpdateForm
        v-if="selectedUser?.user_email"
        :key="selectedUser?.user_email"
        :userObjectData="selectedUser"
        :lensGroups="selectedLensGroups"
        :isNewUser="false"
        @submit-user-form="submitUpdateUserModal"
      />
    </Modal>
  </div>
</template>
<style>
td {
  text-align: -webkit-center;
}
</style>
