<template>
  <div>
    <LensItemsPageHeader :imageName="'box'" :page="'Bursts Archive'" @refech-all="reload" />
    <div class="container px-2 py-4 mx-auto" v-if="file.csv">
      
      <StorageListTable :tableData="file.csv" @tableData:download-file="downloadFile($event)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import StorageListTable from "@core/tables/StorageListTable.vue";
import { onMounted, reactive, computed, onUnmounted, ref } from "vue";

import { useFileStorageStore } from "@modules/admin/store/fileStorageStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import { useRouter } from "vue-router";
import { sleep } from "@/@use/libs/helpers";
import { FileConfig } from "@/index";
import { useUiStore } from "@/@use/uiStore";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const file = useFileStorageStore();

const ui = useUiStore();

const folder = import.meta.env.VITE_CONTAINER_ARCHIVES_BURSTS || "archives-bursts";

const locationId = computed(() => router.currentRoute.value.params.locationId);
const lensItemId = computed(() => lens.selectedLensItems?.lens_id);
const cosmosId = computed(() => router.currentRoute.value.params.cosmosId);
const otherId = computed(() => router.currentRoute.value.params.module ? `${locationId.value}-${router.currentRoute.value.params.module}` : 'generic-burst');

const fileConfig = reactive({
  id: "",
  activation_date: "",
  clientName: "",
  appType: "",
  locationPoint: `${locationId.value}/${otherId.value}`,
  folder: folder,
}) as FileConfig;

/**
 * Définit la configuration du fichier.
 * Cette fonction récupère les informations nécessaires pour configurer le fichier, telles que l'ID de l'objectif sélectionné,
 * la date d'activation, le nom du client et le type d'application.
 *
 * @returns {Promise<void>} Une Promise qui se résout lorsque la configuration du fichier est terminée.
 */
const setFileConfig = async () => {
  await lensGroup.fetchUserAllLensGroup();
  lens.setAllLens();
  fileConfig.id = lens.selectedLensItems?.lens_id as string;
  fileConfig.activation_date = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime as string;
  fileConfig.clientName = lensGroup.selectedLensGroup?.lensGroup_data.metadata.tenant_id?.toLowerCase() as string;
  fileConfig.appType = lensGroup.selectedLensGroup?.lensGroup_data.metadata.application_type as string;
};

/**
 * Cette fonction effectue une opération spécifique dans le fichier BurstsCsv.vue.
   * Elle configure d'abord le fichier, puis met à jour la configuration de l'application en fonction dulensGroups sélectionné.
   * Ensuite, elle sélectionne les éléments de lens en fonction des identifiants de cosmosId et locationId fournis.
   * Elle sélectionne également lelensGroups en fonction de l'identifiant de locationId fourni.
   * Si les éléments de lens et lelensGroups sélectionnés existent, elle attend 200 millisecondes, puis met à jour l'identifiant du fichier dans la configuration.
   * Enfin, elle récupère le fichier CSV de la lens à l'aide de la configuration du fichier.
   *
   * @returns {Promise<void>} Une Promise qui se résout lorsque l'opération est terminée.
   */
const reload = async () => {
  await setFileConfig();
  fileConfig.appType = lensGroup.selectedLensGroup?.lensGroup_data.metadata.application_type as string;
  lens.setSelectedLensItems({ cosmosId: cosmosId.value, locationId: locationId.value });
  lensGroup.setSelectedLensGroups(locationId.value as string);
  if (lens.selectedLensItems && lensGroup.selectedLensGroup) {
    // console.log("reload called");
    sleep(200);
    fileConfig.id = lensItemId.value as string;
    await file.fetchLensCSV(fileConfig);
  }
};

const downloadFile = async ($event: any) => {
  await file.fetchFileItem($event);
};

onMounted(async () => {
  ui.setLoadingState(true);
  await reload();
  if (fileConfig.id) {
    // console.log("fetching csv");
    await file.fetchLensCSV(fileConfig);
  }
  ui.setLoadingState(false);
});

onUnmounted(() => lens.clear());
</script>

<style scoped></style>
