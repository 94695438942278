<template>
  <nav
    class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
  >
    <div class="-mt-px w-0 flex-1 flex cursor-pointer">
      <a
        @click="emit('pagination:prevPage', $event)"
        class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      >
        <ArrowNarrowLeftIcon
          class="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Previous
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <template v-for="page in setting.paging" :key="page">
        <a
          @click="emit('pagination:movePage', page)"
          class="border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer"
          aria-current="page"
          v-if="page === setting.page"
        >
          {{ page }}
        </a>
        <a
          @click="emit('pagination:movePage', page)"
          class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer"
          v-else
        >
          {{ page }}
        </a>
      </template>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end cursor-pointer">
      <a
        @click="emit('pagination:nextPage', $event)"
        class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      >
        Next
        <ArrowNarrowRightIcon
          class="ml-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </a>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/vue/solid";

const props = defineProps<{
  setting: any;
}>();

const emit = defineEmits([
  "pagination:prevPage",
  "pagination:movePage",
  "pagination:nextPage",
]);
</script>
