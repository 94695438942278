<template>
  <div>
    <LensItemsPageHeader :imageName="'report'" :page="title" @refech-all="reload" />
    <div class="container px-2 py-4 mx-auto" v-if="files">
      <StorageListTable :tableData="files" @tableData:download-file="downloadFile($event)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import StorageListTable from "@core/tables/StorageListTable.vue";
import { onMounted, reactive, computed, onUnmounted, ref } from "vue";
import * as _ from "lodash";

import { useFileStorageStore } from "@modules/admin/store/fileStorageStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import { useRouter } from "vue-router";
import { sleep } from "@/@use/libs/helpers";
import { FileConfig } from "@/index";
import { useUiStore } from "@/@use/uiStore";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const file = useFileStorageStore();
const ui = useUiStore();

const locationId = computed(() => router.currentRoute.value.params.locationId);
const lensItemId = computed(() => lens.selectedLensItems?.lens_id);
const cosmosId = computed(() => router.currentRoute.value.params.cosmosId);
const moduleId = computed(() => router.currentRoute.value.params.module);
const url = computed(() => router.currentRoute.value.params.url as string);

const title = computed(() => lens.selectedLensItems?.custom_storage_urls?.find((item: any) => item.url === url.value)?.title);

const fileConfig = reactive({
  id: "",
  activation_date: "",
  clientName: "",
  appType: "",
  locationPoint: '',
  folder: '',
  customUrl: url.value,
}) as FileConfig;

const files = computed(() => {
  return file.customsFiles;
});

const setFileConfig = async () => {
  fileConfig.id = `${lens.selectedLensItems?.lens_id}-${url.value}` as string;
  fileConfig.activation_date = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime as string;
  fileConfig.clientName = lensGroup.selectedLensGroup?.lensGroup_data.metadata.tenant_id?.toLowerCase() as string;
  fileConfig.appType = lensGroup.selectedLensGroup?.lensGroup_data.metadata.application_type as string;
  fileConfig.customUrl = url.value;
};

/**
 * Cette fonction effectue les étapes suivantes :
   * 1. Récupère tous les groupes de lens de l'utilisateur.
   * 2. Définit toutes les lens disponibles.
   * 3. Configure le fichier.
   * 4. Sélectionne les lens en fonction de l'ID du cosmos et de l'ID de l'emplacement.
   * 5. Sélectionne les groupes de lens en fonction de l'ID de l'emplacement.
   * 6. Si des lens et des groupes de lens sont sélectionnés, attend 200ms et récupère le fichier à partir du dossier personnalisé.
   *
   * @returns {Promise<void>} Une Promise qui se résout lorsque toutes les opérations sont terminées.
   */
  const reload = async () => {
  await lensGroup.fetchUserAllLensGroup();
  await lens.setAllLens();
  setFileConfig();
  lens.setSelectedLensItems({ cosmosId: cosmosId.value, locationId: locationId.value });
  lensGroup.setSelectedLensGroups(locationId.value as string);
  if (lens.selectedLensItems && lensGroup.selectedLensGroup) {
    sleep(200);
    fileConfig.id = `${lensItemId.value}-${url.value}`  as string;
    await file.fetchFromCustomFolder(fileConfig);
  }
};

const downloadFile = async ($event: any) => {
  ui.setLoadingState(true);
  await file.fetchFileItem($event);
  ui.setLoadingState(false);
};

onMounted(async () => {
  ui.setLoadingState(true);
  setFileConfig();
  
  if (!_.isEmpty(fileConfig?.id)) {
    await file.fetchFromCustomFolder(fileConfig);
  } else {
    await reload();
  }
  ui.setLoadingState(false);
});

onUnmounted(() => lens.clear());
</script>

<style scoped></style>
