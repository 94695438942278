<script setup lang="ts">
import moment from "moment";
import { useDateTimeStore } from "@use/useDatetime";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

const props = defineProps<{ notifications: any[] }>();

const datetime = useDateTimeStore();


</script>

<template>
  <section class="text-gray-700 body-font relative">
    <div class="container mx-auto items-center px-5 pb-24">
      <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm uppercase py-6">Notifications list:</h2>
      <DataTable v-if="props.notifications?.length > 0"  :value="props.notifications " tableStyle="min-width: 50rem" :sortOrder="-1" sortField="Date" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]">
      <Column field="date" header="Date" :sortable="true">
        <template #body="slotProps">
          {{ moment(slotProps.data.date).format(datetime.time24hFormat) }}
        </template>
      </Column>
      <Column field="message" header="Message"></Column>
    </DataTable>
    <div v-else>Nothing to display on this date</div> 
      
    </div>
  </section>
</template>
