<script lang="ts" setup>
import * as _ from "lodash";
import { computed, onMounted, reactive, ref, watch } from "vue";

import BaseForm from "@/@core/components/dynamicForms/BaseForm.vue";
import { createInputFieldFromObjectKeyValue } from "@/@core/components/dynamicForms/utils/formCreationHelpers";
import MainHeading from "@/@core/shared/ui/headings/MainHeading.vue";
import { useUiStore } from "@/@use/uiStore";
import { ApplicationType, FieldObject } from "@/index";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { storeToRefs } from "pinia";
import Multiselect from "vue-multiselect";
import { useRouter } from "vue-router";

import { useConfigurationStore } from "../store/configurationStore";
import { metadataOptionStore } from "../store/metadataOptionStore";

import { metadataGeneric } from "./metadatas/F2B/generic-metadata-F2B";
import { genericModuleDin41503 } from "./metadatas/F2B/generic_module_din4150_3";
import { genericBurstLogs, genericBurstModuleDin41503Rules, genericBurstRules } from "./metadatas/shared/generic-burst-alarm";
import { genericChartOption } from "./metadatas/shared/generic-chart";
import { genericCSVOption } from "./metadatas/shared/generic-csv";
import { genericStatLogs, genericStatRules } from "./metadatas/shared/generic-stat-alarm";
import { measurementPlanning } from "./metadatas/shared/measurement-planning";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";
import { din4150BaseBurstChartOptions, genericBaseBurstChartOptions } from "./metadatas/shared/burst-charts-Options";
import { genericAlarmOption } from "./metadatas/shared/generic-alarm";

const ui = useUiStore();
const lensGroup = useLensGroupStore();
const configurationStore = useConfigurationStore();
const metadataOptions = metadataOptionStore();
const router = useRouter();
const { isInLoadingState } = storeToRefs(ui);
const formatMetadataToForm = ref<FieldObject[]>();
const clientNameSelected = ref(null);
const applicationTypeSelected = ref<ApplicationType>("generic");
const moduleTypeSelected = ref<string>("NONE");
const isModuleDin41503FFT = ref("NO");

const toaster = createToaster({
  position: "top-right",
});

const applicationOptions = computed(() => metadataOptions.applicationTypes);
const moduleOptions = computed(() => metadataOptions.moduleTypes);
const clientNameOptions = computed(() => configurationStore.organizations.map((o: any) => o.org_display_name));
const newAppId = computed(() => (metadataOptions.lastApplicationId ? `E${metadataOptions.lastApplicationId + 1}` : "E000"));

const newMetadataInput = reactive({
  location_id: newAppId.value,
  latlong: "50.535141|4.973460",
  location_name: "Micromega Device 1",
  group_name: "Micromega Device 1",
  timezone_python: "Europe/Brussels",
  timezone_javascript: "Europe/Brussels",
  group_description: "Rue trou du sart 10 5380 Fernelmont",
  device_sn: "20610011",
});

const client = computed(() => configurationStore.organizations?.filter((o: any) => o.org_display_name === clientNameSelected.value));
const clientName = computed(() => client.value.map((c: any) => c.org_name)[0]);
const enableFFT = computed(() => ["YES", "NO"]);

const initialDataToSend = ref({ ...newMetadataInput });

const recomposeValue = (event: any) => {
  initialDataToSend.value = event;
};

const dataToSend = computed(() => {
  const _metadata = initialDataToSend.value as any;
  /**
   * Vérifie si les nouvelles données de métadonnées sont égales aux données initiales.
   * Si elles sont égales, affiche une erreur et retourne false.
   * Sinon, continue avec les étapes suivantes.
   *
   * Vérifie si le tableau client contient des éléments.
   * Si oui, crée un objet de données contenant les informations de métadonnées.
   * Détermine la valeur de location_id en fonction des conditions suivantes :
   *   - Si _metadata.location_id n'est pas "E000" et n'est pas égal à newAppId.value, utilise _metadata.location_id.
   *   - Sinon, utilise newAppId.value.
   * Copie les autres propriétés de _metadata dans l'objet de données.
   * Ajoute les propriétés client_name et tenant_id à l'objet de données.
   *
   * Initialise un objet vide _toSend.
   *
   * Appelle la fonction measurementPlanning avec data.location_id et assigne le résultat à _planning.
   *
   * Si applicationTypeSelected.value est égal à "generic", configure les propriétés de _toSend comme suit :
   *   - planning : résultat de la fonction measurementPlanning
   *   - chartStat : résultat de la fonction genericChartOption
   *   - csv : résultat de la fonction genericCSVOption
   *   - clientApp : résultat de la fonction formatClientApplication
   *   - alarm : un objet contenant les propriétés root, burstRules, burstLogs, statRules et statLogs
   *     qui sont les résultats des fonctions genericAlarmOption, genericBurstRules, genericBurstLogs,
   *     genericStatRules et genericStatLogs respectivement.
   *   - id : data.location_id
   */
  if (_.isEqual(newMetadataInput, initialDataToSend.value)) {
    toaster.error(`Error! Please don't use default value`);
    return false;
  }
  if (client.value.length > 0) {
    const data = {
      location_id: _metadata.location_id !== "E000" && _metadata.location_id !== newAppId.value ? _metadata.location_id : newAppId.value,
      latlong: _metadata.latlong,
      location_name: _metadata.location_name,
      group_name: _metadata.group_name,
      timezone_python: _metadata.timezone_python,
      timezone_javascript: _metadata.timezone_javascript,
      group_description: _metadata.group_description,
      device_sn: _metadata.device_sn,
      client_name: clientName.value,
      tenant_id: _.lowerCase(_.trim(clientName.value)),
    };
    let _toSend = {};

    const _planning = measurementPlanning(data.location_id);

    if (applicationTypeSelected.value === "generic") {
      _toSend = {
        planning: _planning,
        chartStat: genericChartOption(data.location_id),
        csv: genericCSVOption(data.location_id),
        clientApp: formatClientApplication(data.location_id),
        alarm: {
          root: genericAlarmOption(data.location_id),
          burstRules: genericBurstRules(data.location_id),
          burstLogs: genericBurstLogs(data.location_id),
          statRules: genericStatRules(data.location_id),
          statLogs: genericStatLogs(data.location_id),
        },
        id: data.location_id,
      };

      if (moduleTypeSelected.value === "NONE") {
        // @ts-ignore
        _toSend.chartBurst = genericBaseBurstChartOptions(data.location_id);
        // @ts-ignore
        _toSend.meta = metadataGeneric(data);
      } else if (moduleTypeSelected.value === "din4150-3") {
        if (isModuleDin41503FFT.value === "YES") {
          const _data = Object.assign({}, data, { compute_fft: true });
          // @ts-ignore
          _toSend.chartBurst = din4150BaseBurstChartOptions(_data.location_id);
          // @ts-ignore
          _toSend.meta = genericModuleDin41503(_data);
        } else if (isModuleDin41503FFT.value === "NO") {
          // @ts-ignore
          _toSend.chartBurst = din4150BaseBurstChartOptions(data.location_id);
          // @ts-ignore
          _toSend.meta = genericModuleDin41503(data);
        }

        // @ts-ignore
        _toSend.alarm.moduleRules = genericBurstModuleDin41503Rules(data.location_id);
      }
    }
    return !_.isEmpty(_toSend) ? _toSend : null;
  }
});

async function save() {
  // @ts-ignore
  if (dataToSend.value && dataToSend.value.id) {
    const _dataToSend = dataToSend.value;

    // ui.setLoadingState(true);

    await lensGroup
      .generateFromMetadataLocationDocument(dataToSend.value)
      .then(async () => {
        // @ts-ignore
        return await metadataOptions.updateClientApplicationOption(clientName.value, _dataToSend.clientApp, applicationTypeSelected.value).then(async () => {});
      })
      .finally(() => {
        // ui.setLoadingState(false);
        router.push({ name: "configuration-metadata" });
      });
  }
}

/**
 * Formatte l'application cliente.
 *
 * @param {string} endpoint - L'endpoint de l'application cliente.
 * @returns {object} - L'objet contenant l'endpoint et la liste des types de mesures.
 */
function formatClientApplication(endpoint: string) {
  let appType = "generic";
  return {
    endpoint: endpoint,
    measurement_type_list: [`${endpoint}-${appType}-stat-4`, `${endpoint}-${appType}-burst-2`],
  };
}

const reload = () => {
  formatMetadataToForm.value = createInputFieldFromObjectKeyValue(newMetadataInput);
};

watch(
  () => clientName.value,
  async (val) => {
    if (val) {
      await metadataOptions.fetchClientApplicationOption(clientName.value);
      reload();
    }
  }
);

watch(
  () => applicationOptions.value,
  (val) => {
    if (val) {
      reload();
    }
  }
);

onMounted(() => {
  reload();
});
</script>

<template>
  <div class="container px-2 py-4 mx-auto">
    <div>
      <MainHeading :title="'New metadata configuration'" :subtitle="'Set client name, endpoint & application type'">
        <template v-slot:image> <img class="w-16 h-16" :src="`/img/recipients.svg`" alt="recipients" title="recipients" /></template>
        <template v-slot:button>
          <button
            type="button"
            class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            @click="reload"
            :class="{ isDisabled: isInLoadingState }"
            v-bind:disabled="isInLoadingState"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            <span class="ml-4 flex items-start flex-col leading-none">Reload</span>
          </button>
        </template>
      </MainHeading>
    </div>
    <div>
      <div class="flex flex-col space-y-2 mb-6">
        <label class="text-sm font-semibold text-gray-500">Client Name</label>
        <Multiselect placeholder="client name" v-model="clientNameSelected" :options="clientNameOptions" />
      </div>
      <div class="flex flex-col space-y-2 mb-6">
        <label class="text-sm font-semibold text-gray-500">application type</label>
        <Multiselect placeholder="application type" v-model="applicationTypeSelected" :options="applicationOptions" />
      </div>

      <div class="flex flex-row space-x-10 mb-6">
        <div class="flex flex-1 flex-col space-y-2 mb-6">
          <label class="text-sm font-semibold text-gray-500">Module</label>
          <Multiselect placeholder="application type" v-model="moduleTypeSelected" :options="moduleOptions" />
        </div>

        <div class="flex flex-1 flex-col space-y-2 mb-20" v-if="moduleTypeSelected === 'din4150-3'">
          <label class="text-sm font-semibold text-gray-500 mr-4">compute fft</label>
          <Multiselect v-model="isModuleDin41503FFT" :placeholder="'activate FFT '" :options="enableFFT"></Multiselect>
        </div>
      </div>

      <form class="flex flex-col space-y-5" v-if="clientNameSelected && applicationTypeSelected">
        <div class="h-full bg-blue-100 bg-opacity-75 px-8 pt-8 pb-8 rounded-lg overflow-hidden relative" v-if="formatMetadataToForm">
          <BaseForm :formSchema="formatMetadataToForm" @form-value-updated="recomposeValue($event)" />
        </div>
        <button
          @click.prevent.stop="save"
          class="w-full mt-8 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
        >
          {{ $t("ui.save") }}
        </button>
      </form>
    </div>
  </div>
</template>
