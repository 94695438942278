<script setup lang="ts">
import { useRouter } from "vue-router";

import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, ref, watch } from "vue";

import DoubleDatetimePicker from "@/@core/components/calendar/DoubleDatetimePicker.vue";
import BurstsGenericListTable from "@/@core/tables/BurstsGenericListTable.vue";
import { useAlarmRuleStore } from "@/@modules/admin/store/alarmRuleStore";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useConfigStore } from "@/config/configStore";
import { requestModeler } from "@/config/requestModeler";
import { PhysicalQuantity, QueryParam } from "@/index";
import BurstMultiChartsSwitcher from "@core/charts/BurstMultiChartsSwitcher.vue";
import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import GenericBurstNotificationTable from "@core/tables/notifications/GenericBurstNotificationTable.vue";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";
import { useDateTimeStore } from "@use/useDatetime";
import Chip from 'primevue/chip';

import moment from "moment-timezone";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const burstData = useBurstDataStore();
const graph = useGraphStore();
const metadata = useMetadataStore();
const alarm = useAlarmRuleStore();

const partitionKey = "burst-list-log";
const notificationTableId = ref("00");
const notifications = ref([] as any[]);

const locationId = computed(() => {
  return router.currentRoute.value.params.locationId as string;
});

const cosmosId = computed(() => {
  return router.currentRoute.value.params.cosmosId as string;
});

const id = computed(() => {
  return `${cosmosId.value}-${partitionKey}`;
});

const getBurstListFromCalendar = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_GENERIC_SELECTED_DATE_LOG);
});

const getAllBurstFromListLog = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_GENERIC_ALL_DATE_LOG);
});

const getStartEndDatePicker = computed(() => {
  return datetime.helpers?.startEnd24hourFromDate(datetime.helpers?.today);
});

/**
 * Filtre les journaux de notification en fonction des critères spécifiés.
 *
 * @param {Object} params - Les paramètres de filtrage.
 * @param {string} params.startingDate - La date de début du filtrage.
 * @param {string} params.endDate - La date de fin du filtrage.
 * @param {boolean} params.isToday - Indique si le filtrage doit être effectué pour aujourd'hui seulement.
 */
const filterNotificationLogs = ({ startingDate, endDate, isToday }: { startingDate: string; endDate: string; isToday: boolean }) => {
  // @ts-ignore
  notifications.value = alarm.currentAlarmBurstLog?.logs
    .filter((el: any) => {
      if (isToday) {
        return moment(el.date).isSameOrAfter(startingDate);
      } else {
        return moment(el.date).isBetween(startingDate, endDate);
      }
    })
    .sort((a: any, b: any) => moment(b.date).diff(moment(a.date)));
};

/**
 * Récupère la liste des journaux de connexion des rafales en fonction de la date et de l'identifiant.
 *
 * @param {string} start - La date de début de la période.
 * @param {string} end - La date de fin de la période.
 * @param {any} id - L'identifiant de la localisation.
 * @returns {Promise<void>}
 */
const _fetchBurstListLogByDate = async (start: string, end: string, id: any) => {
  if (getBurstListFromCalendar.value && id) {
    // on modifies les paramètres dans la requete venant de l'API avec les valeurs courantes
    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: start,
      },
      {
        key: configs.cosmosQueryParamsEnum.END_DATE,
        value: end,
      },
      {
        key: configs.cosmosQueryParamsEnum.LOCATION_ID,
        value: id,
      },
    ];
    //  On recompose la requete avec les vraies valeurs
    const query = requestModeler(paramsList, getBurstListFromCalendar.value.query_content);
    await burstData.fetchSelectedLensBurstGenericTable({ query, partitionKey, id });
    filterNotificationLogs({ startingDate: start, endDate: end, isToday: false });
  }
};

/**
 * Récupère toutes les rafales de la liste de journaux.
 * Cette fonction effectue les étapes suivantes :
 * 1. Obtient l'identifiant de la liste de journaux de rafales génériques en fonction de l'identifiant de l'emplacement.
 * 2. Vérifie si la liste de journaux de rafales génériques existe et si l'identifiant de la liste de journaux est défini.
 * 3. Obtient la date de début à partir des metadata dulensGroups sélectionné.
 * 4. Construit les paramètres de requête pour la requête Cosmos DB.
 * 5. Effectue la requête pour récupérer les données de rafales génériques de la liste de journaux.
 * 6. Effectue la requête pour récupérer les journaux d'alarme de la liste de journaux de rafales.
 * 7. Trie les journaux d'alarme par date décroissante.
 */
const fetchAllBurstFromListLog = async () => {
  const burstListLogId = `${locationId.value}-generic-burst`
  if (getAllBurstFromListLog.value && burstListLogId) {
    const startingDate = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime;

    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.LOCATION_ID,
        value: burstListLogId
      },
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: startingDate,
      },
    ] as QueryParam[];
    const query = requestModeler(paramsList, getAllBurstFromListLog.value?.query_content);
   await burstData.fetchSelectedLensBurstGenericTable({ query, partitionKey, id: id.value });
  }

  await alarm.fetchAlarmBurstLog(locationId.value);
  // @ts-ignore
  notifications.value = alarm.currentAlarmBurstLog?.logs.sort((a: any, b: any) => moment(b.date).diff(moment(a.date)));
};

const physicalQuantity = computed(() => metadata.physicalQuantities);

const getPhysicalByType = (type = "velocity") => {
  return physicalQuantity.value?.find((ph: PhysicalQuantity) => ph.type === type);
};

const toExternalPQUnit = (value: any, unit: any) => {
  if (value && unit) {
    const physicalQ = getPhysicalByType(unit);
    const fQ =
      typeof physicalQ?.f_to_external_unit.evaluate === "function"
        ? physicalQ.f_to_external_unit.evaluate({ x: value }).toFixed(physicalQ.user_decimal_digits)
        : value;
    return `${fQ} ${physicalQ?.external_unit}`;
  }
};

const fetchBurstListLogByStartEnd = ($event: any) => {
  _fetchBurstListLogByDate($event.start, $event.end, locationId.value);
};

const genericLensVariables = computed(() => metadata.selectedMetadata.lens_variables);
const genericBurstsChartOption = (burstId: number) => metadata.selectedMetadataGenericBurstChartCustom?.options[`burst_${burstId}`].lens_chart;

const fetchDataFromCosmosById = (id: string, burstId: number) => {
  const lensInfos = Object.assign({}, lens.selectedLensItems, { lens_variables: genericLensVariables.value });
  const lensChartOptions = genericBurstsChartOption(burstId);

  const formatter = lensInfos?.data_type;

  const partitionKey = lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id;
  // const partitionKey = "E11";
  if (partitionKey) {
    // const burstDetails = lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const burstDetails = lens.selectedLensItems?.lens_queries?.find((el: any) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const paramsList = [{ key: configs.cosmosQueryParamsEnum.ID, value: id }];
    // @ts-ignore
    const query = requestModeler(paramsList, burstDetails?.query_content);

    // @ts-ignore
    graph.setSelectedLensBurstGenericGraphCurrent({
      query,
      lensInfos,
      partitionKey,
      lensChartOptions,
      formatter,
    });
  }
};

/**
 * Met à jour les éléments sélectionnés de la lens, récupère tous les groupes de lens de l'utilisateur,
   * sélectionne les groupes de lens correspondants à l'emplacement spécifié, récupère tous les bursts à partir de la liste de logs,
   * et récupère les options de configurations du graphique des bursts pour l'emplacement spécifié.
   *
   * @param {string} cosmosId - L'identifiant Cosmos.
   * @param {string} locationId - L'identifiant de l'emplacement.
   * @returns {Promise<void>} Une Promise qui se résout lorsque toutes les opérations sont terminées.
   */
const reload = async () => {
  lens.setSelectedLensItems({ cosmosId: cosmosId.value, locationId: locationId.value });
  await lensGroup.fetchUserAllLensGroup();
  lensGroup.setSelectedLensGroups(locationId.value as string);
  await fetchAllBurstFromListLog();
  await metadata.fetchOptionsBurstsChartConfigurations(locationId.value as string);
};

onMounted(async () => {
  if (locationId.value) {
   await fetchAllBurstFromListLog();
   await metadata.fetchOptionsBurstsChartConfigurations(locationId.value as string);
  }
});

watch(
  () => locationId.value,
  async () => {
    await reload();
  }
);

watch(
  () => alarm.currentAlarmBurstLog,
  (_, burstAlam) => {
    // @ts-ignore

    notifications.value = burstAlam?.logs;
  }
);
</script>
<template>
  <div>
    <LensItemsPageHeader :imageName="'bursts'" @refech-all="reload" />

    <DoubleDatetimePicker
      @selected-range="fetchBurstListLogByStartEnd"
      :tz="datetime.helpers?.timezone"
      locale="fr-FR"
      :startEndTodayDate="getStartEndDatePicker"
    />
    
    <GenericBurstNotificationTable  v-if="notifications" :notifications="notifications" />

    <div class="container px-2 py-4 mx-auto">
      <BurstsGenericListTable v-if="Array.isArray(burstData.burstGenericTable)" :tableData="burstData.burstGenericTable" @tableData:reload="reload"/>
      <BurstMultiChartsSwitcher :chartList="graph.graphCurrent" />
    </div>
  </div>
</template>

<style scoped></style>
