<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onMounted, unref } from "vue";

import { requestModeler } from "@/config/requestModeler";
import TableData from "@core/components/tables/elements/TableData.vue";
import useTable from "@core/components/tables/elements/useTable";
import { burstListTableColumns } from "./burstListTableColumns";

import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useConfigStore } from "@/config/configStore";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";
import { helpers } from "@use/libs/helpers";
import { useDateTimeStore } from "@use/useDatetime";
import * as _ from "lodash";

import moment from "moment-timezone";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToggleSwitch from "primevue/toggleswitch";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";

const emit = defineEmits(["tableData:select-bursts-id", "tableData:update"]);
const props = defineProps<{ tableData: any[] }>();

const metadata = useMetadataStore();

const DECIMALS_PPV = 3;
const DECIMALS = 1;

const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const lens = useLensStore();
const graph = useGraphStore();
const lensGroup = useLensGroupStore();

const toDecimalPPV = (cell: any, row: any) => helpers.toDecimals(cell, DECIMALS_PPV);
const toDecimal = (cell: any, row: any) => helpers.toDecimals(cell, DECIMALS);
const toDate = (cell: any, row: any) => datetime.helpers.to24HourFormat(cell);

const lensVariables = computed(() => metadata.selectedMetadata.lens_variables);

const lensChartsZc = [
  {
    type: "bursts_timeseries_3axes",
    title: "Peak Particle Velocities",
    lens_id: "din4150-3L2-burst-2",
    x_label: "Time",
    x_range: [0, 0],
    y_label: "PPV",
    y_range: [-10, 10],
    chart_number: 0,
    physical_quantity: "velocity",
    virtual_channels: ["VC_3", "VC_4", "VC_5"],
  },
];

const lensChartsFFT = [
  {
    type: "bursts_fft_3axes",
    title: "Peak Particle Velocities FFT",
    lens_id: "din4150-3L2-burst-2",
    x_label: "Freq",
    x_range: [0, 0],
    y_label: "",
    y_range: [0, 1],
    chart_number: 0,
    physical_quantity: "velocity",
    virtual_channels: ["PV-X-FFT", "PV-Y-FFT", "PV-Z-FFT"],
  },
];

const options = {
  mainOrder: "datetime",
  mainOrderType: "date",
};
const table = useTable();
const { updatedData } = storeToRefs(table);

const localIgnoredList = computed(() =>
  props.tableData
    .filter((el: any) => !el.visible)
    .map((el: any) => ({
      name: el.name,
      datetime: el.datetime,
      checked: el.visible,
    }))
);

const fetchDataFromCosmosById = (id: string) => {
  const lensInfos = lens.selectedLensItems;
  const formatter = lensInfos?.data_type || "";
  const partitionKey = lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id;
  if (partitionKey) {
    // @ts-ignore
    lensInfos.lens_variables = unref(lensVariables.value);

    const burstDetails = lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const paramsList = [{ key: configs.cosmosQueryParamsEnum.ID, value: id }];
    // @ts-ignore
    const query = requestModeler(paramsList, burstDetails?.query_content);

    const lensCharts = formatter === "module-bursts-din41503-fft" ? { fft: lensChartsFFT, raw: lensChartsZc } : lensChartsZc;

    graph.setSelectedLensBurstGenericGraphCurrent({
      query,
      lensInfos,
      partitionKey,
      lensChartOptions: lensCharts,
      formatter,
    });
  }
};
const sortItem = ($event: any) => {
  console.log($event);
};
const burstsColumns = burstListTableColumns({
  fetchDataFromCosmosById,
  toDecimalPPV,
  toDecimal,
  toDate,
});

const CSVColsNames = [
  { value: "ID", key: "name" },
  // { value: "Deb. Enreg.", key: "rawDatetime" },
  { value: "Localisation", key: "localisation" },

  { value: "PPV-TRANS - PPV [mm/s]", key: "x_ppv" },
  { value: "PPV-TRANS - Freq [Hz]", key: "x_freq" },

  { value: "PPV-LONG - PPV [mm/s]", key: "y_ppv" },
  { value: "PPV-LONG - Freq [Hz]", key: "y_freq" },

  { value: "PPV-VERT - PPV [mm/s]", key: "z_ppv" },
  { value: "PPV-VERT - Freq [Hz]", key: "z_freq" },

  { value: "PVS [mm/s]", key: "pvs_ppv" },
  { value: "PVS time [s]", key: "pvs_time" },
];

const burstListIgnore = (lensId: string, ignoredList: any[]) => {
  return {
    id: `${lensId}-burst-list-log-ignore`,
    partitionKey: "burst-list-log-ignore",
    data_burst_list_ignore: ignoredList,
  };
};

const convertToCSV = () => {
  const localisation = `${lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id}/${lensGroup.selectedLensGroup?.lensGroup_name}`;

  const colNames = CSVColsNames;
  const arr = props.tableData?.filter((el) => el.visible === true);
  const headers = colNames.map((name) => name.value).join(" , ");

  const csvContent = arr
    ?.map((it) => {
      return colNames
        ?.map((col) => {
          if (col.key === "rawDatetime") {
            return datetime.helpers.toDate(it[col.key]);
          } else if (col.key === "localisation") {
            return localisation;
          } else return it[col.key];
        })
        .join(" , ");
    })
    .join(" \n ");

  const result = [headers, csvContent].join(" \n ");
  var element = document.createElement("a");
  element.href = "data:text/csv;charset=utf-8," + encodeURI(result);
  element.target = "_blank";
  element.download = `${lens.selectedLensItems?.lens_id}-${new Date().toISOString()}.csv`;
  element.click();
};


const handleToggleSwitchSaveFilter = () => {
  const id = lens.selectedLensItems?.lens_id;
  if (id) {
    const ignoredList = _.uniqBy([...localIgnoredList.value], "name");
    const data = burstListIgnore(id, ignoredList);
    useBurstDataStore().saveBurstListIgnore(data);
  }
};


onMounted(() => {
  if (props.tableData) {
    table.setInitialSwitchData(props.tableData, "visible");
  }
});
</script>

<template>
  <div>
    <div class="flex flex-row">
      <button
        @click="convertToCSV"
        class="px-4 py-2 my-6 text-md font-semibold text-white transition-colors duration-300 bg-indigo-500 rounded-md shadow hover:bg-indigo-600 focus:outline-none focus:ring-indigo-200 focus:ring-4"
      >
        {{ $t("common.exportCSV") }}
      </button>
    </div>
    <!-- <TableData :columns="burstsColumns" :rows="props.tableData" :options="options" @sort-item="sortItem($event)" /> -->
    <DataTable :value="props.tableData" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="datetime" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
      <ColumnGroup type="header">
        <Row>
          <Column header="Visible" :rowspan="2"/>
          <Column field="datetime" header="Datetime" :sortable="true" :rowspan="2" />
          <Column field="pvs_ppv" header="PVS" :sortable="true" :rowspan="2" />
          <Column field="pvs_time" header="PVS time [s]" :sortable="true" :rowspan="2" />
          <Column header="PPV-TRAN" :colspan="2"/>
          <Column header="PPV-LONG" :colspan="2"/>
          <Column header="PPV-VERT" :colspan="2"/>
          <Column header="" :rowspan="2" />
        </Row>
        <Row>
          <Column header="freq [Hz]" />
          <Column header="ppv [mm/s]" />
          <Column header="freq [Hz]" />
          <Column header="ppv [mm/s]" />
          <Column header="freq [Hz]" />
          <Column header="ppv [mm/s]" />
        </Row>
      </ColumnGroup>
      <Column field="visible">
        <template #body="slotProps">
          <ToggleSwitch v-model="slotProps.data.visible" @change="handleToggleSwitchSaveFilter" />
        </template> 
      </Column>
      <Column field="datetime">
        <template #body="slotProps">
          <div>
            {{ moment(slotProps.data.datetime).format(datetime.time24hFormat) }}
          </div>
        </template> 
      </Column>
      <Column field="pvs_ppv">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.x_freq.toFixed(3) }}
          </div>
        </template> 
      </Column>
      <Column field="pvs_time">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.x_freq.toFixed(3) }}
          </div>
        </template> 
      </Column>
      <Column field="x_freq">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.x_freq.toFixed(1) }}
          </div>
        </template> 
      </Column>
      <Column field="x_time">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.x_ppv.toFixed(3) }}
          </div>
        </template> 
      </Column>
      <Column field="y_freq">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.y_freq.toFixed(1) }}
          </div>
        </template> 
      </Column>
      <Column field="y_time">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.y_ppv.toFixed(3) }}
          </div>
        </template> 
      </Column>
      <Column field="z_freq">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.z_freq.toFixed(1) }}
          </div>
        </template> 
      </Column>
      <Column field="z_time">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.z_ppv.toFixed(3) }}
          </div>
        </template> 
      </Column>
      <Column header="">
        <template #body="slotProps">
          <div style="width: 100px;display: flex;">
            <Button class="m-auto inline-block px-4 py-1" label="show" severity="info" @click="fetchDataFromCosmosById(slotProps.data.name)" raised/>
          </div>
        </template> 
      </Column>
    </DataTable>
  </div>
</template>