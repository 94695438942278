<template>
  <div>
   <DataTable :value="tableData" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="name" paginator :rows="15" :rowsPerPageOptions="[10, 20, 50]">
      <Column field="name" header="Name" :sortable="true">
      <template #body="slotProps">
        {{ _.last(_.split(slotProps.data.name, "/")) }}
      </template>
      
      </Column>
      <Column field="createdOn" header="Created on" :sortable="true">
        <template #body="slotProps">
          {{ moment(slotProps.data.createdOn).format("D MMM, yyyy HH:mm:ss") }}
        </template>
      </Column>
      <Column header="Download">
        
        <template #body="slotProps">
    
          <div style="width: 160px;display: flex;">
          <Button class="m-auto inline-block px-4 py-1" label="Download" @click="emit('tableData:download-file', slotProps.data)" severity="info" raised />
          
          </div>
        </template>
      </Column>
    </DataTable> 
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import * as _ from "lodash";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

const props = defineProps<{ tableData: any[] }>();
const emit = defineEmits(["tableData:download-file"]);

</script>
