import { inject, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { defineStore } from "pinia";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";
import { useConfigStore } from "@/config/configStore";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useAPI } from "@/@use/useAPI";

interface AuthResult {
  token: {
    accessToken: string;
    expiresIn: string;
  };
  user: {
    deletedAt?: boolean;
    id: number;
    user_email: string;
    user_fullname: string;
    user_lensGroups: any[];
    user_org: any | null;
    user_role: any | null;
    username: string;
  };
}

export const useAuthStore = defineStore(
  "auth",
  () => {
    const $zo: any = inject("$zo");
    const API = useAPI();
    const router = useRouter();
    const route = useRoute();

    const token = ref();
    const user = ref();

    const lensGroup = useLensGroupStore();
    const lens = useLensStore();
    const configs = useConfigStore();
    const burstData = useBurstDataStore();
    const graph = useGraphStore();

    const APISettings = reactive({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value?.accessToken}`,
      },
      baseURL: `${import.meta.env.VITE_BASE_URL}`,
    });

    const getAuth = () => {
      try {
        const _auth = localStorage.getItem("auth") || false;
        const _user = localStorage.getItem("user") || false;
        if (_auth && _user) {
          const res = JSON.parse(_auth);
          user.value = JSON.parse(_user);
          token.value = res.token;
          APISettings.headers.Authorization = res?.token?.accessToken;

          return res?.token?.accessToken;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setAuth = (res: any) => {
      if (!res.user.deletedAt) {
        localStorage.setItem("auth", JSON.stringify(res.token));
        localStorage.setItem("user", JSON.stringify(res.user));
        user.value = res.user;
        token.value = res.token;

        APISettings.headers.Authorization = res?.token?.accessToken;
      } else {
        router?.push({ name: "auth-register" });
      }
    };

    const getMe = async () => {
      try {
        const res = await API.getData("/auth/me");

        if (res.value.success) {
          localStorage.setItem("user", JSON.stringify(res.value.data));
          user.value = res.value.data;

          $zo.setRoles(res.value.data.user_role?.role_slug);
          $zo.setPermissions(res.value.data.user_role?.permissions);
        }
      } catch (error) {}
    };

    const logout = () => {
      token.value = null;
      user.value = null;
      lensGroup.$reset();
      lens.$reset();
      configs.$reset();
      burstData.$reset();
      graph.$reset();
      localStorage.clear();
      window.location.reload();
    };

    watch(token, () => {
      APISettings.headers.Authorization = `Bearer ${token.value?.accessToken}`;
    });

    onMounted(() => {
      if (route.meta.requiresAuth) {
        getAuth();
      }
    });

    return {
      getMe,
      getAuth,
      setAuth,
      token,
      user,
      APISettings,
      logout,
    };
  },
  { persist: true }
);
