<script setup lang="ts">
import { XIcon } from "@heroicons/vue/outline";
import { v4 as uuidv4 } from "uuid";
import { ref, watch } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "w-[46rem]",
    required: false,
  },
});
const emit = defineEmits(["reset"]);
const showModal = ref(false);
const fakeList = ref(["fake"]);
const modalId = ref("");

function closeModal() {
  modalId.value = uuidv4();
  showModal.value = false;
  emit("reset");
}

watch(
  () => props.show,
  (show) => {
    modalId.value = uuidv4();
    showModal.value = show;
  }
);
</script>

<template>
  <teleport to="body" :key="modalId">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="showModal" ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
        <div class="flex items-start justify-center min-h-screen pt-24 text-center">
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              class="bg-white rounded-lg text-left overflow shadow-xl p-8"
              :class="width"
              role="dialog"
              ref="modal"
              aria-modal="true"
              v-if="showModal"
              aria-labelledby="modal-headline"
            >
              <XIcon @click="closeModal" class="h-5 w-5 float-right cursor-pointer" />

              <div v-for="item in fakeList" :key="modalId">
                <slot></slot>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>
