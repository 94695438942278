/// index.d.ts
import "v-calendar/dist/style.css";
import 'vue-tel-input/vue-tel-input.css';
import "./main.css";

import "leaflet";
import cloneDeep from "lodash.clonedeep";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// @ts-ignore
import VCalendar from "v-calendar";
import { createApp } from "vue";

import App from "./App.vue";

import router from "./router";
import { i18n } from "./locales";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { commonAPI } from "./api";
import { ZoOption } from "./@use/zo/types/typing";
// @ts-ignore
import { VueZo } from "./@use/zo";
// @ts-ignore
import VueTelInput from 'vue-tel-input';

//test-commit
// import VueTailwindModal from "vue-tailwind-modal";
export function resetStore({ store }: { store: any }) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => store.$patch(cloneDeep(initialState));
}

declare class VueZo {
  static install: (app: any, options?: ZoOption) => void;
}
declare class VCalendar {
  static install: (app: any, options?: any) => void;
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(resetStore);

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(VCalendar, {})
  .use(VueTelInput)
  //ts-ignore
  .use(VueZo, { persistent: true });

app.config.globalProperties.$axios = commonAPI;

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      cssLayer: {
        name: 'primevue',
        order: 'tailwind-base, primevue, tailwind-component, tailwind-utilities'
      }
    }
  }
});

// app.use(VueAxios, commonAPI);
app.use(i18n);
// app.component("VueTailwindModal", VueTailwindModal);
app.provide("axios", commonAPI);

router.isReady().then(() => {
  app.mount("#app");
});
