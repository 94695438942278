<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";

import { UserUpdate } from '@/index';
import { useConfigurationStore } from "@modules/configuration/store/configurationStore";

import MultiSelect from "primevue/multiselect";
import Select from "primevue/select";
import InputText from "primevue/inputtext";

import { v4 as uuidv4 } from "uuid";


const props = defineProps<{
  userObjectData: UserUpdate;
  isNewUser: Boolean;
  lensGroups: any[];
}>();

const data = ref({...props.userObjectData})

const emit = defineEmits(["submit-user-form"]);

const configurationStore = useConfigurationStore();

/**
 * Computed property that processes and sorts lens groups with additional details.
 * 
 * This computed property performs the following operations:
 * 1. Maps over the `props.lensGroups` array and adds a new property `lensGroup_name` 
 *    which is a concatenation of `location_id`, `lensGroup_application_type`, and `lensGroup_name`.
 * 2. Sorts the resulting array in ascending order based on the numeric ID extracted from `lensGroup_name`.
 * 
 * @returns {Array} An array of lens groups with additional details, sorted by the numeric ID in `lensGroup_name`.
 */
const lensGroupsWithDetails = computed(() => {
  return props.lensGroups
    .map((g: any) => {
      return {
        ...g,
        lensGroup_name: `${g.location_id} - ${g.lensGroup_application_type} - ${g.lensGroup_name}`,
      };
    })
    .sort((a, b) => {
      const idA = parseInt(a.lensGroup_name.match(/E(\d+)/)[1], 10);
      const idB = parseInt(b.lensGroup_name.match(/E(\d+)/)[1], 10);
      return idA - idB;
    }); // Tri par ordre croissant basé sur l'ID
});



/**
 * Creates a new user by merging the user object data from props and the current form data,
 * then emits a "submit-user-form" event with the merged form data.
 *
 * @function createNewUser
 * @emits submit-user-form - Emits an event with the merged form data.
 */
function createNewUser() {
  const formData = { ...props.userObjectData, ...data.value };
  emit("submit-user-form", formData);
}

/**
 * Sorts the user lens groups based on the numerical value extracted from the lens group names.
 * 
 * This function performs the following steps:
 * 1. Creates a map (lensGroupMap) to store the mapping between the numerical ID extracted from the lens group name and the group ID.
 * 2. Iterates over the lensGroupsWithDetails array to populate the lensGroupMap.
 * 3. Sorts the user_lensGroups array in the data object based on the numerical IDs extracted from the lens group names.
 * 
 * The lens group names are expected to contain a numerical value prefixed with 'E', e.g., 'E123'.
 * The numerical value is extracted using a regular expression and used for sorting.
 */

function sortUserLensGroups() {
  const lensGroupMap = new Map();
  lensGroupsWithDetails.value.forEach(group => {
    const id = parseInt(group.lensGroup_name.match(/E(\d+)/)[1], 10);
    lensGroupMap.set(id, group.id);
  });

  data.value.user_lensGroups.sort((a, b) => {
    const idA = Array.from(lensGroupMap.keys()).find(key => lensGroupMap.get(key) === a);
    const idB = Array.from(lensGroupMap.keys()).find(key => lensGroupMap.get(key) === b);
    return idA - idB;
  });
}

/**
 * Lifecycle hook called when the component is mounted.
 * It triggers the sorting of user lens groups.
 */
onMounted(() => {
  sortUserLensGroups();
});

/**
 * Watches the `data` object for changes and triggers the `sortUserLensGroups` function
 * whenever any property within `data` changes.
 * 
 * @param {Object} data - The object being watched for changes.
 * @param {Function} sortUserLensGroups - The function to be called when changes are detected.
 * @param {Object} options - Additional options for the watcher.
 * @param {boolean} options.deep - Indicates that the watcher should deeply observe changes within the `data` object.
 */
watch(data, () => {
  sortUserLensGroups();
}, { deep: true });

</script>

<template>
  <div v-if="userObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ isNewUser ? "Create new user" : "Update user" }}
      </h1>
      <form @submit.prevent="createNewUser" class="flex flex-col space-y-5">
      <label for="username">Nickname</label>
      <InputText v-model="data.username" id="username" name="username" type="text" autocomplete="username"
        placeholder="recovib" required="true" />

      <label for="email">User account (Email)</label>
      <InputText v-model="data.user_email" id="email" name="email" type="email" autocomplete="email"
        placeholder="user_email@recovib-io.com" required="true" />
      
      <label for="fullName">Full Name</label>
      <InputText v-model="data.user_fullname" id="fullName" name="fullName" type="text" autocomplete="fullName"
        required="true" />
      
      <label for="user_role">User Role</label>

      <Select v-model="data.user_role" :id="uuidv4()" optionValue="id" optionLabel="role_name" :options="configurationStore.roles" />

      <label for="user_org">User Organization</label>
      <Select v-model="data.user_org" :id="uuidv4()" optionValue="id" optionLabel="org_display_name" :options="configurationStore.organizations" />
      
      <label for="user_lensGroups">Assigned device(s)</label>
      <MultiSelect v-model="data.user_lensGroups" :id="uuidv4()" optionValue="id" optionLabel="lensGroup_name" :options="lensGroupsWithDetails" filter display="chip" />

      <button type="submit" class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4">
            {{ isNewUser ? "Create" : "Update" }}
      </button>


      </form>
    </div>
  </div>
</template>

<style>

</style>
