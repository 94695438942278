<script setup lang="ts">
import { ref, reactive } from "vue";
import { PlusIcon } from "@heroicons/vue/outline";
import MainHeading from "@core/shared/ui/headings/MainHeading.vue";
import OrgRecipientCreateUpdateForm from "@core/forms/OrgRecipientCreateUpdateForm.vue";
import Modal from "@core/shared/ui/Modal.vue";

import { useRecipientStore } from "@modules/admin/store/recipientStore";

import RecipientDelete from "./RecipientDelete.vue";
import { v4 as uuidv4 } from "uuid";
import { useAuthStore } from "@/@modules/auth/authStore";


import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';


const recipientStore = useRecipientStore();

const showCreateOrgModal = ref<boolean>(false);
const showUpdateOrgModal = ref<boolean>(false);
const showDeleteOrgModal = ref<boolean>(false);
const selectedRecipient = ref<any>();

const tableId = ref(uuidv4());

const newRecipient = reactive({
  name: "-/-",
  email: "-/-",
  phone: "-/-",
  id: uuidv4(),
});

/**
 * Modifie le destinataire sélectionné.
 *
 * @param {any} user - L'utilisateur à modifier.
 * @returns {void}
 */
function editSelectedRecipient(user: any) {
  selectedRecipient.value = user;
  showUpdateOrgModal.value = !showUpdateOrgModal.value;
}

/**
 * Supprime le destinataire sélectionné.
 *
 * @param {any} user - L'utilisateur à supprimer.
 * @returns {void}
 */
function deleteSelectedRecipient(user: any) {
  showDeleteOrgModal.value = !showDeleteOrgModal.value;
  selectedRecipient.value = user;
}

/**
 * Supprime un destinataire.
 *
 * Cette fonction effectue les étapes suivantes:
 * 1. Génère un nouvel identifiant de table en utilisant la fonction uuidv4().
 * 2. Supprime le destinataire sélectionné de la liste des destinataires dans le store recipientStore.
 * 3. Effectue une requête asynchrone pour récupérer la liste mise à jour des destinataires en utilisant la fonction fetchRecipientList() du store recipientStore.
 * 4. Masque le modal de suppression de l'organisation en définissant la valeur de showDeleteOrgModal à false.
 *
 * @returns {Promise<void>} Une promesse qui se résout lorsque la suppression du destinataire est terminée.
 */
async function deleteRecipient() {
  tableId.value = uuidv4();
  recipientStore.removeRecipientList(selectedRecipient.value);
  await recipientStore.fetchRecipientList();
  showDeleteOrgModal.value = false;
}

/**
 * Ajoute un élément à la liste des destinataires.
 *
 * @param {any} formData - Les données du formulaire à ajouter à la liste des destinataires.
 * @returns {Promise<void>} - Une promesse qui se résout lorsque l'ajout est terminé.
 */
async function addToRecipientList(formData: any) {
  tableId.value = uuidv4();
  recipientStore.updateClientRecipientList(formData);
  await recipientStore.fetchRecipientList();
  showCreateOrgModal.value = false;
}
/**
 * Met à jour la liste des destinataires avec les données fournies.
 *
 * @param {any} formData - Les données du formulaire de mise à jour du destinataire.
 * @returns {Promise<void>} - Une promesse qui se résout lorsque la mise à jour est terminée.
 */
async function updateRecipientList(formData: any) {
  tableId.value = uuidv4();
  recipientStore.updateClientRecipientList(formData);
  await recipientStore.fetchRecipientList();
  showUpdateOrgModal.value = false;
}
</script>
<template>
  <div class="container px-2 py-4 mx-auto">
    <MainHeading :title="useAuthStore().user?.user_org?.org_display_name + ' - Recipients notifications management'" subtitle="Add, update, delete recipient">
      <template v-slot:button>
        <button
          @click="showCreateOrgModal = !showCreateOrgModal"
          class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
        >
          <PlusIcon class="h-6 w-6" />
          <span class="ml-4 flex items-start flex-col leading-none">Add recipient</span>
        </button>
      </template>
    </MainHeading>


    <DataTable :value="recipientStore.recipients" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="name">
      <Column field="name" header="Full Name" :sortable="true"></Column>
      <Column field="email" header="Email"></Column>
      <Column field="phone" header="Phone"></Column>
      <Column header="">
        <template #body="slotProps">
          <div style="width: 160px;display: flex;">
            <Button class="m-auto inline-block px-4 py-1" label="Edit" @click="editSelectedRecipient(slotProps.data)" severity="info" raised/>
            <Button class="m-auto inline-block px-4 py-1" label="Delete" @click="deleteSelectedRecipient(slotProps.data)" severity="danger" raised/>
          </div>
        </template> 
      </Column>
    
    </DataTable>
    <Modal :show="showDeleteOrgModal" @reset="showDeleteOrgModal = false">
      <RecipientDelete :data="selectedRecipient" @confirm="deleteRecipient" @reset="showDeleteOrgModal = false" />
    </Modal>
    <Modal :show="showCreateOrgModal" @reset="showCreateOrgModal = false">
      <OrgRecipientCreateUpdateForm :recipientObjectData="newRecipient" :isNewRecipient="true" @submit-form="addToRecipientList" />
    </Modal>
    <Modal :show="showUpdateOrgModal" @reset="showUpdateOrgModal = false">
      <OrgRecipientCreateUpdateForm
        v-if="selectedRecipient?.email"
        :key="selectedRecipient?.email"
        :recipientObjectData="selectedRecipient"
        :isNewRecipient="false"
        @submit-form="updateRecipientList"
      />
    </Modal> 
  </div>
</template>
<style scoped>
td {
  text-align: -webkit-center;
}
</style>
